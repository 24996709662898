import { Col, Space, Modal, Row, Typography } from 'antd'
import '../../assets/styles/modalTerminos.scss'
import React from 'react'

const { Title, Text } = Typography;

const ModalTerminosCondiciones = ({ isModalOpen, handleOk, handleCancel }) => {
  return (
    <Modal
      title={
        // <Flex justify='center'>
        <label className='textoTitulo' style={{ color: '#3D3D3D' }}>Términos y condiciones</label>
        // </Flex>
      }

      centered
      open={isModalOpen}
      onOk={handleOk}
      footer={[]}
      width={800}
      onCancel={handleCancel}
      className='modal-term-cond'
      closeIcon={//se dejo en duro el svg para poder cambiar su color con css
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" className="hover-effect">
          <path d="M14.3955 9.59497L9.60352 14.387" stroke="#666666" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
          <path d="M14.3971 14.3899L9.60107 9.5929" stroke="#666666" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
          <path fill-rule="evenodd" clip-rule="evenodd" d="M16.3345 2.75024H7.66549C4.64449 2.75024 2.75049 4.88924 2.75049 7.91624V16.0842C2.75049 19.1112 4.63549 21.2502 7.66549 21.2502H16.3335C19.3645 21.2502 21.2505 19.1112 21.2505 16.0842V7.91624C21.2505 4.88924 19.3645 2.75024 16.3345 2.75024Z" stroke="#666666" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
        </svg>
      }
    >
      <div className='scroll-container margin-sin-scroll' >
        <div >
          <Title className='tituloTerminos'>A. TÉRMINOS Y CONDICIONES DE ACCESO Y USO DE SITIO WEB PLATAFORMA MARKETPLACE</Title>
          <Text className='textoCuerpoTerminos'>A continuación, se describen los términos y condiciones legales (los “Términos y Condiciones”) sobre los cuales Holding Bursátil Regional S.A. (la “Empresa” o “HBR”) identificada con RUT 77.726.203 - 3, domiciliada en Chile, representada legalmente por Don Patricio Rojas Sharovsky, ofrece a usted acceso al sitio web: www.marketplace.bolsadesantiago.com, junto con cualquier subdominio relacionado (el “Sitio web”), como usuario del mismo (“Usuario”).</Text>
          <br />
          <br />
          <Text className='textoCuerpoTerminos'>Por favor lea la Política de Privacidad y los siguientes Términos y Condiciones antes de utilizar nuestro Sitio web. Al acceder y usar nuestro Sitio web, significa que usted acepta estos Términos y Condiciones y la Política de Privacidad del Sitio web. Cualquier cambio en los Términos y Condiciones será informado a Usted al correo electrónico asociado a su Cuenta, según este término se define más adelante, y se entenderá que Usted acepta dicha modificación si accede al Servicio luego de informada la modificación referida.</Text>
          <br />
          <br />
          <Text className='textoCuerpoTerminos'>Todos los términos y condiciones propuestos por Usted que sean adicionales o que entren en conflicto con estos Términos y Condiciones son expresamente rechazados por la HBR y no tendrán fuerza ni efecto.</Text>
          <Title level={4} className='tituloTerminos'>1. Disclaimer</Title>
          <Text className='textoCuerpoTerminos'>Cualquier material, información y/o contenido del Sitio web (“Contenido”) se proporciona “tal cual”, por lo que la HBR, sus empleados, funcionarios, directores, agentes y licenciadores o las compañías de su grupo empresarial (“las Compañías”) no pueden garantizar y no garantizan la disponibilidad del Contenido o Sitio web, ni su precisión, integridad, actualidad, puntualidad, no infracción, título, comerciabilidad o idoneidad para algún propósito en particular que Usted le dé al Contenido o al Sitio web en sí mismo.</Text>
          <br />
          <br />
          <Text className='textoCuerpoTerminos'>El Contenido es sólo para fines informativos y no pretende constituir una oferta de valores, consejo de inversión o asesoramiento comercial. El Contenido no tiene en cuenta los objetivos de inversión de los Usuarios que lo visitan, su situación financiera o necesidades particulares. Es aconsejable que el Usuario, antes de actuar sobre cualquier información contenida en el Sitio web o Contenido, busque asesoramiento profesional que le permita complementar y contrastar la información que encontrará en el Sitio web.</Text>
          <br />
          <br />
          <Text className='textoCuerpoTerminos'>El Contenido se revisa en profundidad antes de su publicación, sin embargo, no es posible garantizar que se encuentre absolutamente libre de errores mecanográficos, defectos de redacción y problemas equivalentes, por lo que HBR recomienda a los Usuarios que estén atentos a las posibles actualizaciones o rectificaciones que el Sitio web incorpore y que confirmen los datos que resulten de interés esencial para ellos a través de los cauces de comunicación personal, telefónica y correo electrónico que se proporcionan en este mismo Sitio web y en los medios de comunicación.</Text>
          <br />
          <br />
          <Text className='textoCuerpoTerminos'>En ningún caso HBR, sus empleados, funcionarios, directores, agentes o licenciadores y/o las Compañías serán responsables ante usted o cualquier tercero por cualquier tipo de pérdida financiera, pérdida de ganancias, cualquier daño moral, especial, incidental o de cualquier tipo de daño similar, resultante directa o indirectamente del uso del Contenido o Sitio web.</Text>
          <br />
          <br />
          <Text className='textoCuerpoTerminos'>En ningún caso, HBR, sus empleados, funcionarios, directores, agentes, licenciadores o las Compañías serán responsables ante usted o cualquier tercero por cualquier decisión o acción tomada por usted o cualquier tercero en función del Contenido o del Sitio web en sí mismo.</Text>
          <Title level={4} className='tituloTerminos'>2. Restricciones Jurídicas</Title>
          <Text className='textoCuerpoTerminos'>Sin limitar lo anterior, usted comprende que las leyes relativas a los contratos financieros varían en todo el mundo, y es su obligación asegurarse de cumplir plenamente con cualquier ley, reglamento o directiva, aplicable a su país de residencia con respecto al uso del Sitio web. La capacidad de acceder al Sitio web no significa necesariamente que el Sitio web y/o el Contenido, sean legales según las leyes, regulaciones o directivas aplicables en su país de residencia.</Text>
          <Text className='textoCuerpoTerminos'>3. Restricciones de Uso y Acceso</Text>
          <Text className='textoCuerpoTerminos'>Usted será responsable de proporcionar y mantener los medios por los cuales accede al Sitio web, que pueden incluir, entre otros, su computadora personal, ipad, tablet o dispositivo móvil, hardware de conectividad y líneas de telecomunicaciones o acceso a internet.</Text>
          <br />
          <br />
          <Text className='textoCuerpoTerminos'>Usted declara y garantiza que ha implementado y planea operar y mantener la protección adecuada en relación con la seguridad y el control de su computadora y cualquier información y datos incluidos en ella.</Text>
          <br />
          <br />
          <Text className='textoCuerpoTerminos'>Usted acepta que HBR no será responsable de ninguna manera ante usted en caso de falla, daño o destrucción en el Sitio web, de datos o registros o cualquier parte de los mismos, o por demoras, pérdidas, errores u omisiones que resulten de la falla o mala administración de cualquier equipo de computación, software o línea de telecomunicación que usted emplee para acceder al Sitio web. Usted acepta que de ninguna manera, ya sea directa o indirectamente, expondrá a HBR, o a cualquiera de los proveedores de servicio de HBR, a virus informáticos u otro material o dispositivo igualmente dañino o inapropiado.</Text>
          <br />
          <br />
          <Text className='textoCuerpoTerminos'>Sin limitar la generalidad de lo anterior, el uso del Sitio web por parte de usted como Usuario está sujeto a las siguientes restricciones:</Text>
          <br />
          <br />

          <Row style={{ marginLeft: 5 }}>
            <Col span={24}>
              <Space align="start" size={5}>
                <label className='textoCuerpoTerminos'>1.</label>
                <Text className='textoCuerpoTerminos'>No puede usar, vender, alquilar, arrendar, copiar, modificar, distribuir, redistribuir, licenciar, sublicenciar, ejecutar o exhibir públicamente, publicar, editar, crear trabajos derivados o hacer un uso no autorizado del Sitio web y/o cualquier Contenido y marcas de HBR o de las Compañías (“Marcas”), sin el previo consentimiento explícito por escrito de HBR o las Compañías según sea el caso. Asimismo, no podrá modificar, realizar trabajos derivados, desensamblar, realizar una compilación inversa o realizar ingeniería inversa en ninguna parte del Sitio web, sin el previo consentimiento explícito por escrito de HBR;</Text>
              </Space>
            </Col>
          </Row>
          <Row style={{ marginLeft: 5 }}>
            <Col span={24}>
              <Space align="start" size={5}>
                <label className='textoCuerpoTerminos'>2.</label>
                <Text className='textoCuerpoTerminos'>Salvo que se indique expresamente en este documento, ninguna parte del Sitio web, el Contenido y/o Marcas contenidas en el mismo pueden copiarse, reproducirse, distribuirse, comercializarse, volver a publicarse, descargarse, mostrarse, publicarse o transmitirse de ninguna forma ni por ningún medio, sin el previo consentimiento explícito por escrito de HBR o las Compañías según sea el caso. Si el Usuario elige descargar Contenido, debe hacerlo de acuerdo con los Términos y Condiciones y HBR le autoriza dicha descarga solo para su uso personal y no comercial y no le transfiere ningún derecho sobre el mismo;</Text>
              </Space>
            </Col>
          </Row>
          <Row style={{ marginLeft: 5 }}>
            <Col span={24}>
              <Space align="start" size={5}>
                <label className='textoCuerpoTerminos'>3.</label>
                <Text className='textoCuerpoTerminos'>El Sitio web así como el Contenido no se pueden utilizar para construir una base de datos de ningún tipo, así como tampoco se puede almacenar el Sitio web (en su totalidad o cualquier parte) en bases de datos para que usted o un tercero accedan a él o para distribuir cualquier servicio de bases de datos que contenga todo o parte del Sitio web. No puede utilizar el Sitio web de ninguna manera para mejorar la calidad de los datos vendidos o aportados por usted a terceros;</Text>
              </Space>
            </Col>
          </Row>
          <Row style={{ marginLeft: 5 }}>
            <Col span={24}>
              <Space align="start" size={5}>
                <label className='textoCuerpoTerminos'>4.</label>
                <Text className='textoCuerpoTerminos'>No debe usar el Contenido o el Sitio web para crear un servicio similar o competitivo;</Text>
              </Space>
            </Col>
          </Row>
          <Row style={{ marginLeft: 5 }}>
            <Col span={24}>
              <Space align="start" size={5}>
                <label className='textoCuerpoTerminos'>5.</label>
                <Text className='textoCuerpoTerminos'>No puede utilizar el Sitio web en relación con material que promueva actividades ilegales o la violación de cualquier ley o regulación local, estatal, nacional o internacional, incluidas, entre otras, las leyes que rigen la propiedad intelectual y otros derechos de propiedad (incluidos, pero no limitado a, patentes, marcas registradas, secretos comerciales, derechos de autor o cualquier información confidencial, de propiedad o secreto comercial de un tercero), protección de la información y privacidad, incluido, entre otros, el contenido que difunde la información personal de otra persona sin su permiso;</Text>
              </Space>
            </Col>
          </Row>
          <Row style={{ marginLeft: 5 }}>
            <Col span={24}>
              <Space align="start" size={5}>
                <label className='textoCuerpoTerminos'>6.</label>
                <Text className='textoCuerpoTerminos'>El Sitio web no puede ser utilizado ni accedido por ninguna máquina automatizada, scrapping, robot, araña o cualquier otra función o servicio automatizado;</Text>
              </Space>
            </Col>
          </Row>
          <Row style={{ marginLeft: 5 }}>
            <Col span={24}>
              <Space align="start" size={5}>
                <label className='textoCuerpoTerminos'>7.</label>
                <Text className='textoCuerpoTerminos'>No debe hacerse pasar por otra persona o entidad ni tergiversar la afiliación, conexión o asociación con ninguna persona o entidad ni utilizar información de contacto fraudulenta, engañosa o inexacta;</Text>
              </Space>
            </Col>
          </Row>
          <Row style={{ marginLeft: 5 }}>
            <Col span={24}>
              <Space align="start" size={5}>
                <label className='textoCuerpoTerminos'>8.</label>
                <Text className='textoCuerpoTerminos'>No puede eliminar, eludir, deshabilitar, dañar o interferir de otro modo con ninguna función del Sitio web, ni intentar obtener acceso no autorizado a ninguna parte de los mismos a través de ningún medio, ni interferir, corromper o interrumpir el funcionamiento o desempeño del Sitio web o la capacidad de cualquier otra persona para utilizarlo (incluido, entre otros, el intento de degradar el rendimiento de los servidores de alguna manera);</Text>
              </Space>
            </Col>
          </Row>
          <Row style={{ marginLeft: 5 }}>
            <Col span={24}>
              <Space align="start" size={5}>
                <label className='textoCuerpoTerminos'>9.</label>
                <Text className='textoCuerpoTerminos'>No puede violar los derechos de privacidad, publicidad y otros derechos de otros usuarios o terceros, o recolectar, raspar, agregar datos, extraer datos, raspar la pantalla, indexar o recopilar datos e información sobre otros usuarios o terceros sin su consentimiento, ya sea de forma manual o automática con el uso de cualquier medio, incluidos, entre otros, bots, rastreadores, arañas, tamices y probadores de carga, sin el consentimiento expreso por escrito de HBR, o participar en pruebas, pen-testing, sniffing o monitoreo de los servicios, sus sistemas, software o hardware de cualquier forma;</Text>
              </Space>
            </Col>
          </Row>
          <Row style={{ marginLeft: 5 }}>
            <Col span={24}>
              <Space align="start" size={5}>
                <label className='textoCuerpoTerminos'>10.</label>
                <Text className='textoCuerpoTerminos'>No puede utilizar el Sitio web en material que una persona razonable podría considerar: ofensivo, inexacto, incompleto, abusivo, obsceno, censurable, difamatorio, calumnioso, fraudulento o engañoso, indecente, pornográfico, profano, amenazante, acoso o intimidación, angustiante, vulgar, odioso, malicioso, dañino para menores, racial o étnicamente ofensivo, intolerancia, odio o daño físico de cualquier tipo contra cualquier grupo o individuo, o menospreciar la agenda religiosa, política o legal de cualquier persona o entidad, o que sea de otra manera inconsistente con estos Términos y Condiciones, incluida cualquiera de nuestras políticas; Usted acepta informar cualquier violación de los Términos y Condiciones por parte de otras personas de las que tenga conocimiento.</Text>
              </Space>
            </Col>
          </Row>
          <Title level={4} className='tituloTerminos'>4. Derechos de la Empresa</Title>
          <Text className='textoCuerpoTerminos'>HBR se reserva el derecho de modificar, suspender o interrumpir el funcionamiento del Sitio web o de cualquier parte o secciones del mismo en cualquier momento y sin previo aviso. Usted acepta que HBR no será responsable ante usted o ningún tercero y no se admitirán reclamaciones contra la Empresa en relación a ello.</Text>
          <Title level={4} className='tituloTerminos'>5. Fuerza Mayor</Title>
          <Text className='textoCuerpoTerminos'>Usted acepta que HBR no será responsable de ninguna manera ante Usted o cualquier otra persona en caso de que exista fuerza mayor (incluido, entre otros, el acto de cualquier gobierno o autoridad legal), por la falla, daño o destrucción de sus sistemas informáticos, datos o registros o cualquier parte de los mismos.</Text>
          <Title level={4} className='tituloTerminos'>6. Problemas Técnicos</Title>
          <Text className='textoCuerpoTerminos'>Usted comprende que, los problemas técnicos u otras condiciones pueden retrasar o impedir que acceda al Sitio web.</Text>
          <br />
          <br />
          <Text className='textoCuerpoTerminos'>De acuerdo a la legislación aplicable, HBR no será responsable, y usted acepta no responsabilizar ni buscar responsabilizar a HBR ni a ninguno de sus agentes o proveedores de servicios, por cualquier problema técnico, demoras, fallas del Sitio web, fallas en la línea de comunicación, fallas en el equipo o software, o por demoras, pérdidas, errores u omisiones que resulten de la falla o mala administración de cualquier equipo o software de telecomunicaciones o de computadora, problemas de acceso al Sitio web, problemas de capacidad del sistema, alta demanda de tráfico de Internet, y otros problemas y defectos informáticos similares.</Text>
          <br />
          <br />
          <Text className='textoCuerpoTerminos'>HBR no garantiza que usted podrá acceder o utilizar el Sitio web en los momentos o lugares que elija, o que HBR tendrá la capacidad adecuada para el Sitio Web en su conjunto o en cualquier ubicación geográfica.</Text>
          <Title level={4} className='tituloTerminos'>7. Contenido del Usuario</Title>
          <Text className='textoCuerpoTerminos'>“Contenido del Usuario” significa toda la información y el contenido que Usted ingresa o envía al Sitio web. Usted asume todas las responsabilidades y riesgos asociados con el uso del Contenido del Usuario, incluida su veracidad, precisión, integridad y el uso del mismo por parte de terceros que accedan a él en el Sitio web, así como cualquier consecuencia por su publicidad o divulgación más allá del Sitio web.</Text>
          <br />
          <br />
          <Text className='textoCuerpoTerminos'>Por la presente, Usted declara y garantiza que: (a) es el propietario del Contenido del Usuario o tiene suficientes derechos y autoridad sobre el mismo; (b) el Contenido del Usuario no viola los Términos y Condiciones ni la Política de Privacidad, ni ninguna ley, regulación o directiva aplicable; (c) el Contenido del Usuario no contiene ningún virus, adware, spyware, worms u otro código dañino o malicioso; y (d) usted autoriza a HBR a usar el Contenido del Usuario de acuerdo a los Términos y Condiciones y a la Política de Privacidad.</Text>
          <br />
          <br />
          <Text className='textoCuerpoTerminos'>HBR no está obligado a realizar copias de seguridad de ningún Contenido del Usuario y éste podrá ser eliminado en caso de que HBR deje de tener una base de legalidad para procesarlo. Usted es el único responsable de crear las respectivas copias de seguridad.</Text>
          <br />
          <br />
          <Text className='textoCuerpoTerminos'>Al publicar o cargar Contenido del Usuario en el Sitio web, Usted, con las limitaciones de la legislación aplicable, otorga a HBR una licencia mundial irrevocable, no exclusiva, libre de regalías y totalmente pagada para reproducir, distribuir públicamente, mostrar y ejecutar, preparar trabajos derivados, incorporarlos a otros trabajos y utilizar de cualquier otro modo el Contenido del Usuario, y otorgar sublicencias de lo anterior.</Text>
          <br />
          <br />
          <Text className='textoCuerpoTerminos'>HBR se reserva el derecho (pero no tiene la obligación, excepto en la medida en que lo exija la ley aplicable) de revisar cualquier Contenido del Usuario, investigar y/o tomar las medidas adecuadas contra Usted a su entera discreción (incluida la eliminación o modificación de su Contenido del Usuario y/o informarlo a las autoridades policiales).</Text>
          <Title level={4} className='tituloTerminos'>8. Marcas Comerciales y Derechos de Autor</Title>
          <Text className='textoCuerpoTerminos'>Todos los derechos, títulos e intereses relacionados con el Sitio web, el Contenido y las Marcas, incluidas, entre otras, las marcas comerciales, las marcas de servicios, los nombres comerciales y el logotipo "NuamExchange" y el de las Compañías son propiedad de HBR, las Compañías u otros otorgantes de licencias y están protegidos por leyes de derechos de autor, propiedad intelectual y marcas registradas y tratados internacionales.</Text>
          <br />
          <br />
          <Text className='textoCuerpoTerminos'>Usted acepta no eliminar ningún aviso de derechos de autor u otras indicaciones de derechos de propiedad intelectual protegidos del Contenido que imprima o descargue del Sitio web. Usted no obtendrá ningún derecho de propiedad intelectual, ni ningún derecho o licencia para utilizar dicho Contenido, salvo lo establecido expresamente en estos Términos y Condiciones.</Text>
          <br />
          <br />
          <Text className='textoCuerpoTerminos'>Las imágenes que se muestran en el Sitio web son propiedad de HBR, de las Compañías o de sus licenciantes. Usted acepta no cargar, publicar, reproducir o distribuir ninguna información, software u otro material protegido por derechos de autor o cualquier otro derecho de propiedad intelectual (como derechos de imagen), o que pudiere afectar la privacidad o la protección de datos personales, sin antes obtener el permiso del titular o propietario de dichos derechos y el consentimiento previo por escrito de HBR.</Text>
          <br />
          <br />
          <Text className='textoCuerpoTerminos'>Nada de lo contenido en el Sitio web puede interpretarse como una concesión, cesión, renuncia, por implicación, impedimento legal o de otro modo, cualquier licencia o derecho para usar cualquier marca comercial, imagen o dato personal, sin el permiso por escrito de HBR, de las Compañías o de cualquier tercero que pueda ser propietario de tales derechos. El uso del Contenido, excepto por lo dispuesto en este documento, está estrictamente prohibido.</Text>
          <br />
          <br />
          <Text className='textoCuerpoTerminos'>Si cree que uno de nuestros Usuarios o que el Sitio web está infringiendo derechos de propiedad intelectual y desea que se elimine el material presuntamente infractor, comuníquese directamente en la sección “contacto” en el Sitio web.</Text>
          <br />
          <br />
          <Text className='textoCuerpoTerminos'>Para cualquier consulta relacionada con derechos de propiedad intelectual, comuníquese directamente en la sección “contacto” en el Sitio web.</Text>
          <Title level={4} className='tituloTerminos'>9. Incumplimiento</Title>
          <Text className='textoCuerpoTerminos'>Usted acepta indemnizar sin límite, defender, declarar indemne y eximir de toda responsabilidad a HBR, a las Compañías y a sus respectivos funcionarios, directores y empleados de cualquier reclamo, demanda, responsabilidad, daño, pérdida, costo y gastos, incluidos los honorarios legales, que puedan surgir como resultado de: (i) cualquier incumplimiento grave de los Términos y Condiciones y la Política de Privacidad por su parte o (ii) incumplimiento grave por su parte de cualquier ley, regulación, directiva o los derechos de un tercero, y que cause un perjuicio directo a la HBR, y/o sus afiliadas corporativas y sus respectivos funcionarios, directores y empleados.</Text>
          <br />
          <br />
          <Text className='textoCuerpoTerminos'>Sin perjuicio de cualquier otro derecho establecido en estos Términos y Condiciones, si incumple total o parcialmente cualquier disposición contenida en este documento, HBR se reserva el derecho de tomar las medidas que se consideren oportunas.</Text>
          <Title level={4} className='tituloTerminos'>10. Ley Aplicable y Jurisdicción</Title>
          <Text className='textoCuerpoTerminos'>Estos Términos y Condiciones se regirán por las leyes de Chile sin tener en cuenta los conflictos de principios legales de las mismas, independientemente de si usted reside en cualquier otro lugar del mundo y/o si ha accedido al Sitio web desde cualquier parte del mundo.</Text>
          <br />
          <br />
          <Text className='textoCuerpoTerminos'>HBR opera el Sitio web en Chile, por lo que HBR no garantiza que el Sitio web y sus derechos de autor, marcas comerciales, patentes y acuerdos de licencia, sean apropiados o estén disponibles para su uso en lugares distintos de Chile. Si accede a este sitio desde ubicaciones fuera de Chile, lo hace por su propia iniciativa y bajo su propio riesgo, y usted es el único responsable del cumplimiento de las leyes locales, en la medida en que las leyes locales sean aplicables.</Text>
          <br />
          <br />
          <Text className='textoCuerpoTerminos'>Este Sitio web está controlado y operado por HBR desde sus oficinas en Santiago, Chile. HBR no garantiza que los materiales de este Sitio web sean apropiados para su uso en otros lugares. Si alguna parte de estos Términos y Condiciones se considera ilegal, nula o inaplicable, esa parte se considerará separable y no afectará la validez y aplicabilidad de las disposiciones restantes.</Text>
          <br />
          <br />
          <Text className='textoCuerpoTerminos'>Usted acepta someterse a la jurisdicción personal y exclusiva de los tribunales ubicados dentro de Chile para resolver cualquier disputa que pueda surgir en relación a ello.</Text>
          <Title level={4} className='tituloTerminos'>11. Otros</Title>
          <Text className='textoCuerpoTerminos'>Los títulos de las secciones de los Términos y Condiciones se utilizan únicamente para su conveniencia y la de HBR, careciendo de significado legal o contractual.</Text>
          <br />
          <br />
          <Text className='textoCuerpoTerminos'>Los Términos y Condiciones, la Política de Privacidad y cualquier otro término y condición de servicio que se incluya en el Sitio web, constituyen el acuerdo completo entre Usted y HBR que rige el uso del Sitio web.</Text>
          <Title className='tituloTerminos'>B. POLÍTICA DE PRIVACIDAD Y PROTECCIÓN DE DATOS PERSONALES</Title>

          <Title level={4} className='tituloTerminos'>1. General</Title>
          <Text className='textoCuerpoTerminos'>Holding Bursátil Regional S.A. ("Empresa" o "HBR") es la propietaria del sitio web marketplace.bolsadesantiago.com ("Sitio web"). HBR se compromete a proteger el tratamiento de sus datos personales y su privacidad. Esta política de privacidad y protección de datos personales (“Política de Privacidad”) está diseñada para informarle sobre el tipo de datos personales recolectados, procesados y almacenados por HBR a través del Sitio web y para qué finalidades se usan. También le informaremos cómo puede ejercer sus derechos de titular de esos datos personales.</Text>
          <br />
          <br />
          <Text className='textoCuerpoTerminos'>HBR quiere que Usted siempre esté al tanto de los datos personales que entrega al Sitio web, teniendo control sobre la información que comparte tanto en su navegación como cuando completa nuestros formularios de contacto, se suscribe a newsletters o contacta a nuestro servicio al cliente. HBR declara que respeta la privacidad y el derecho a la autodeterminación informativa de toda persona y que es el responsable de los datos tratados en el Sitio web de conformidad con la Política de Privacidad y las leyes de la República de Chile.</Text>
          <br />
          <br />
          <Text className='textoCuerpoTerminos'>Esta Política de Privacidad no se aplica a los sitios web o aplicaciones accesibles a través de hipervínculos que usted pudiere encontrar en el Sitio web, salvo aquellos que lo dirijan a sitios web o aplicaciones de HBR. Se deja especial constancia que esta Política de Privacidad es aplicable a todos y cada uno de los productos y servicios desarrollados por HBR y que contrate a través del Sitio Web mediante su cuenta de usuario.</Text>
          <Title level={4} className='tituloTerminos'>2. Consentimiento para tratamiento de datos personales</Title>
          <Text className='textoCuerpoTerminos'>La base legal principal para el tratamiento de los datos personales en este Sitio web es su aceptación explícita para que el Sitio web recolecte, procese, trate y almacene sus datos según esta Política de Privacidad. Al usar el Sitio web Usted acepta y consiente expresamente que se traten sus datos personales de acuerdo con esta política.</Text>
          <br />
          <br />
          <Text className='textoCuerpoTerminos'>Usted declara ser mayor de 18 años.</Text>
          <br />
          <br />
          <Text className='textoCuerpoTerminos'>Le recomendamos que revise esta Política de Privacidad detenidamente y que la consulte periódicamente para que la comprenda junto a sus cambios posteriores, si los hubiere. Si no está de acuerdo con los términos de esta Política de Privacidad, no nos suministre dato alguno y deje de utilizar el Sitio web inmediatamente.</Text>
          <br />
          <br />
          <Text className='textoCuerpoTerminos'>Cualquier cambio a esta Política de Privacidad se publicará en el Sitio web y se le solicitará que acepte los cambios.</Text>
          <br />
          <br />
          <Text className='textoCuerpoTerminos'>Las definiciones de los Términos y Condiciones se aplican a esta Política de Privacidad, a menos que se indique lo contrario. Además de esta Política de Privacidad, es su obligación revisar los Términos y Condiciones que se incorporan aquí por referencia.</Text>
          <Title level={4} className='tituloTerminos'>3. ¿Qué datos personales recopilamos?</Title>
          <Text className='textoCuerpoTerminos'>HBR recolecta, procesa y almacena sólo los datos personales que son estrictamente necesarios para el correcto funcionamiento del Sitio web, para mejorar la experiencia de los Usuarios en el mismo y para que el Usuario pueda contratar los servicios ofrecidos en el Sitio web. Cualquier procesamiento adicional de los datos personales sólo se realiza sobre la base de otras obligaciones o permisos legales, o si Usted ha prestado su consentimiento expreso a HBR.</Text>
          <br />
          <br />
          <Text className='textoCuerpoTerminos'>HBR recolecta, procesa y almacena datos personales de la forma que se indica a continuación:</Text>
          <br />
          <br />
          <Row >
            <Col span={24}>
              <Text className='textoCuerpoTerminos'>Recolección y almacenamiento no automático de datos personales:</Text>
            </Col>
          </Row>
          <Row style={{ marginLeft: 20 }}>
            <Col span={24}>
              <Space align="start" size={5}>
                <label className='pointerSeparator'>■</label>
                <Text className='textoCuerpoTerminos'>Cuando Usted accede y/o usa el Sitio web entrega sus datos personales para determinadas finalidades, HBR recolecta, procesa y almacena estos datos personales. A continuación, los datos que se recolectan bajo esta modalidad:</Text>
              </Space>
            </Col>
          </Row>

          <Row style={{ marginLeft: 40 }}>
            <Col span={24}>
              <Space align="start" size={5}>
                <label className='textoCuerpoTerminos countRomano'>i.</label>
                <Text className='textoCuerpoTerminos'>Nombre y apellido del Usuario.</Text>
              </Space>
            </Col>
          </Row>

          <Row style={{ marginLeft: 40 }}>
            <Col span={24}>
              <Space align="start" size={5}>
                <label className='textoCuerpoTerminos countRomano'>ii.</label>
                <Text className='textoCuerpoTerminos'>Correo electrónico del Usuario.</Text>
              </Space>
            </Col>
          </Row>
          <Row style={{ marginLeft: 40 }}>
            <Col span={24}>
              <Space align="start" size={5}>
                <label className='textoCuerpoTerminos countRomano'>iii.</label>
                <Text className='textoCuerpoTerminos'>Número de identificación del Usuario.</Text>
              </Space>
            </Col>
          </Row>
          <Row style={{ marginLeft: 40 }}>
            <Col span={24}>
              <Space align="start" size={5}>
                <label className='textoCuerpoTerminos countRomano'>iv.</label>
                <Text className='textoCuerpoTerminos'>Fecha de nacimiento del Usuario.</Text>
              </Space>
            </Col>
          </Row>
          <Row style={{ marginLeft: 40 }}>
            <Col span={24}>
              <Space align="start" size={5}>
                <label className='textoCuerpoTerminos countRomano'>v.</label>
                <Text className='textoCuerpoTerminos'>Nacionalidad del Usuario.</Text>
              </Space>
            </Col>
          </Row>
          <Row style={{ marginLeft: 40 }}>
            <Col span={24}>
              <Space align="start" size={5}>
                <label className='textoCuerpoTerminos countRomano'>vi.</label>
                <Text className='textoCuerpoTerminos'>Dirección del Usuario.</Text>
              </Space>
            </Col>
          </Row>
          <Row style={{ marginLeft: 40 }}>
            <Col span={24}>
              <Space align="start" size={5}>
                <label className='textoCuerpoTerminos countRomano'>vii.</label>
                <Text className='textoCuerpoTerminos'>Teléfono de contacto del Usuario.</Text>
              </Space>
            </Col>
          </Row>
          <Row style={{ marginLeft: 40 }}>
            <Col span={24}>
              <Space align="start" size={5}>
                <label className='textoCuerpoTerminos countRomano'>viii.</label>
                <Text className='textoCuerpoTerminos'>Profesión u oficio del Usuario.</Text>
              </Space>
            </Col>
          </Row>
          <br />
          <Text className='textoCuerpoTerminos'>Por otra parte, en el caso de que el Usuario sea una persona jurídica, HBR recolecta, procesa y almacena los siguientes datos personales del Usuario administrador y del Representante legal del Usuario:</Text>
          <Row style={{ marginLeft: 40 }}>
            <Col span={24}>
              <Space align="start" size={5}>
                <label className='textoCuerpoTerminos countRomano'>i.</label>
                <Text className='textoCuerpoTerminos'>Nombre y apellido del Representante legal y del Administrador de la Cuenta del Usuario.</Text>
              </Space>
            </Col>
          </Row>
          <Row style={{ marginLeft: 40 }}>
            <Col span={24}>
              <Space align="start" size={5}>
                <label className='textoCuerpoTerminos countRomano'>ii.</label>
                <Text className='textoCuerpoTerminos'>Correo electrónico del Representante legal y del Administrador de la Cuenta del Usuario.</Text>
              </Space>
            </Col>
          </Row>
          <Row style={{ marginLeft: 40 }}>
            <Col span={24}>
              <Space align="start" size={5}>
                <label className='textoCuerpoTerminos countRomano'>iii.</label>
                <Text className='textoCuerpoTerminos'>Número de identificación del Representante legal y del Administrador de la Cuenta del Usuario.</Text>
              </Space>
            </Col>
          </Row>
          <Row style={{ marginLeft: 40 }}>
            <Col span={24}>
              <Space align="start" size={5}>
                <label className='textoCuerpoTerminos countRomano'>iv.</label>
                <Text className='textoCuerpoTerminos'>Nacionalidad del Representante legal del y del Administrador de la Cuenta Usuario.</Text>
              </Space>
            </Col>
          </Row>
          <Row style={{ marginLeft: 40 }}>
            <Col span={24}>
              <Space align="start" size={5}>
                <label className='textoCuerpoTerminos countRomano'>v.</label>
                <Text className='textoCuerpoTerminos'>País de residencia del Representante legal y del Administrador de la Cuenta del Usuario.</Text>
              </Space>
            </Col>
          </Row>
          <Row style={{ marginLeft: 40 }}>
            <Col span={24}>
              <Space align="start" size={5}>
                <label className='textoCuerpoTerminos countRomano'>vi.</label>
                <Text className='textoCuerpoTerminos'>Teléfono de contacto del Representante legal y del Administrador de la Cuenta del Usuario.</Text>
              </Space>
            </Col>
          </Row>
          <Text className='textoCuerpoTerminos'>Estos datos personales se recopilarán a través del Sitio web cuando el Usuario introduzca información en alguno de los campos destinados a tal efecto. No es necesario que Usted proporcione esta información para poder utilizar el Sitio web, sin embargo, es necesario que Usted proporcione esta información para crear su Cuenta y así poder contratar los productos y/o servicios de la HBR que se ofrecen en el Sitio web a los que puede acceder a través del Sitio web. Si elige proporcionar esta información, HBR la conservará en sus registros y la procesará siempre que Usted acepte el tratamiento de sus datos de acuerdo con las disposiciones de esta Política de Privacidad. Es necesario que Usted proporcione esta información para la creación de su cuenta de usuario, la que lo habilitará para contratar los diferentes productos y servicios de la HBR que puede contratar a través del Sitio web.</Text>
          <Title level={4} className='tituloTerminos'>4. ¿Cuáles son las finalidades para procesar los datos personales?</Title>
          <Text className='textoCuerpoTerminos'>Los datos personales serán procesados por HBR con las siguientes finalidades:</Text>

          <Row style={{ marginLeft: 40 }}>
            <Col span={24}>
              <Space align="start" size={5}>
                <label className='textoCuerpoTerminos countRomano'>i.</label>
                <Text className='textoCuerpoTerminos'>Ayudar a establecer y verificar la identidad del Usuario.</Text>
              </Space>
            </Col>
          </Row>
          <Row style={{ marginLeft: 40 }}>
            <Col span={24}>
              <Space align="start" size={5}>
                <label className='textoCuerpoTerminos countRomano'>ii.</label>
                <Text className='textoCuerpoTerminos'>Crear la Cuenta del Usuario en el Sitio web.</Text>
              </Space>
            </Col>
          </Row>
          <Row style={{ marginLeft: 40 }}>
            <Col span={24}>
              <Space align="start" size={5}>
                <label className='textoCuerpoTerminos countRomano'>iii.</label>
                <Text className='textoCuerpoTerminos'>Contratación de servicios a través del Sitio web.</Text>
              </Space>
            </Col>
          </Row>
          <Row style={{ marginLeft: 40 }}>
            <Col span={24}>
              <Space align="start" size={5}>
                <label className='textoCuerpoTerminos countRomano'>iv.</label>
                <Text className='textoCuerpoTerminos'>Mantener la seguridad e integridad de los sistemas, instalaciones y personal de HBR.</Text>
              </Space>
            </Col>
          </Row>
          <Row style={{ marginLeft: 40 }}>
            <Col span={24}>
              <Space align="start" size={5}>
                <label className='textoCuerpoTerminos countRomano'>v.</label>
                <Text className='textoCuerpoTerminos'>Prestar asistencia: comunicarse y responder a preguntas, consultas, comentarios, sugerencias o reclamos del Usuario, requerimientos de información al Usuario.</Text>
              </Space>
            </Col>
          </Row>
          <Row style={{ marginLeft: 40 }}>
            <Col span={24}>
              <Space align="start" size={5}>
                <label className='textoCuerpoTerminos countRomano'>vi.</label>
                <Text className='textoCuerpoTerminos'>Prestar asistencia técnica y soporte al Usuario.</Text>
              </Space>
            </Col>
          </Row>
          <Row style={{ marginLeft: 40 }}>
            <Col span={24}>
              <Space align="start" size={5}>
                <label className='textoCuerpoTerminos countRomano'>vii.</label>
                <Text className='textoCuerpoTerminos'>Enviar al Usuario actualizaciones y correos electrónicos o mensajes sobre ofertas de productos o servicios que son de su interés, avisos de promociones y otra información sobre productos, incluyendo comunicaciones con fines publicitarios e informativos, Usted podrá autorizar o no que HBR le envíe ofertas de marketing sobre productos o servicios, noticias o correos con novedades de la Empresa haciendo click en la casilla “autorizo el envío de publicidad”.</Text>
              </Space>
            </Col>
          </Row>
          <Row style={{ marginLeft: 40 }}>
            <Col span={24}>
              <Space align="start" size={5}>
                <label className='textoCuerpoTerminos countRomano'>viii.</label>
                <Text className='textoCuerpoTerminos'>Mantener registros, como listas de correo o listas de tipos de Usuarios, por preferencias o características, con el objeto de mantener al Usuario informado sobre productos y servicios de su interés. HBR podrá elaborar perfiles en base a estudios de marketing y técnicas y procedimientos estadísticos y de segmentación que permitan personalizar la oferta de productos y servicios adecuados a las características y necesidades del Usuario.</Text>
              </Space>
            </Col>
          </Row>
          <Row style={{ marginLeft: 40 }}>
            <Col span={24}>
              <Space align="start" size={5}>
                <label className='textoCuerpoTerminos countRomano'>ix.</label>
                <Text className='textoCuerpoTerminos'>Mejorar el Sitio web tomando en cuenta las preferencias del Usuario.</Text>
              </Space>
            </Col>
          </Row>
          <br />
          <Title level={4} className='tituloTerminos'>5. ¿Quién más recibe los datos personales?</Title>
          <Text className='textoCuerpoTerminos'>Los datos personales también podrán ser compartidos con proveedores o terceros externos ("Receptores") para su correcto procesamiento, quienes procesarán los datos para las finalidades autorizadas y de acuerdo a las instrucciones de HBR. Los Receptores autorizados son: (i) terceros que prestan servicios a HBR para poder brindar los servicios de análisis, correo electrónico y verificación de datos; (ii) terceros que prestan servicios a HBR en relación con el funcionamiento del Sitio web; y (iii) auditores, contratistas u otros asesores que auditen cualquiera de los procesos comerciales de HBR.</Text>
          <br />
          <br />
          <Text className='textoCuerpoTerminos'>Cualquier procesamiento realizado por Receptores se regirá por un mandato o acuerdo de procesamiento de datos en la forma requerida por la ley, que se basa en su aceptación explícita, preservando los Usuarios todos y cada uno de sus derechos legales de protección de datos y obligando a los Receptores a respetar la información personal y manejarla de acuerdo con las leyes pertinentes. Los Receptores deben mantener la confidencialidad de dicha información y utilizar los datos personales solo en el curso de la prestación de los servicios prestados a HBR y solo para los fines que dicte HBR.</Text>
          <br />
          <br />
          <Text className='textoCuerpoTerminos'>De acuerdo a la legislación vigente, HBR también puede compartir los datos personales en casos especiales, tales como: (i) cumplir con la ley, regulación, citación, solicitud de una autoridad aplicable u orden judicial; (ii) detectar, prevenir o abordar el fraude, la seguridad, la infracción de sus políticas de seguridad o problemas técnicos; (iii) hacer cumplir las disposiciones de esta Política de Privacidad o cualquier otro acuerdo entre usted y HBR, incluida la investigación de posibles violaciones de la misma; (iv) proteger contra daños a los derechos, propiedad o seguridad de la Empresa, sus socios, las Compañías, Usuarios o el público.</Text>
          <Title level={4} className='tituloTerminos'>6. Derechos del Usuario y retención</Title>
          <Text className='textoCuerpoTerminos'>El Usuario en cualquier momento, siempre que se cumplan los requisitos respectivos, puede hacer valer los siguientes derechos mediante el envío de una solicitud escrita dirigida a HBR, en la sección “Contacto” en el Sitio web, indicando en la referencia del e-mail “Política de Privacidad HBR”:</Text>
          <br />
          <br />

          <Row style={{ marginLeft: 15 }}>
            <Col span={24}>
              <Space align="start" size={5}>
                <label className='pointerSeparator'>■</label>
                <Text className='textoCuerpoTerminos'>Derecho de acceso: el Usuario tiene derecho a acceder a sus datos personales para conocer qué datos personales suyos se están procesando y cómo se procesan. En particular podrá pedir la siguiente información:</Text>
              </Space>
            </Col>
          </Row>
          <Row style={{ marginLeft: 40 }}>
            <Col span={24}>
              <Space align="start" size={5}>
                <label className='textoCuerpoTerminos countRomano'>i.</label>
                <Text className='textoCuerpoTerminos'>Los datos tratados y su origen.</Text>
              </Space>
            </Col>
          </Row>
          <Row style={{ marginLeft: 40 }}>
            <Col span={24}>
              <Space align="start" size={5}>
                <label className='textoCuerpoTerminos countRomano'>ii.</label>
                <Text className='textoCuerpoTerminos'>La finalidad o finalidades del tratamiento.</Text>
              </Space>
            </Col>
          </Row>
          <Row style={{ marginLeft: 40 }}>
            <Col span={24}>
              <Space align="start" size={5}>
                <label className='textoCuerpoTerminos countRomano'>iii.</label>
                <Text className='textoCuerpoTerminos'>Las categorías, clases o tipos de destinatarios, o bien, la identidad de cada destinatario, en caso de solicitarlo así el Usuario, a los que se les hayan comunicado o cedido los datos o se prevea hacerlo.</Text>
              </Space>
            </Col>
          </Row>
          <Row style={{ marginLeft: 15 }}>
            <Col span={24}>
              <Space align="start" size={5}>
                <label className='pointerSeparator'>■</label>
                <Text className='textoCuerpoTerminos'>Derecho a pedir la rectificación de datos personales: el Usuario tiene derecho a pedir la rectificación y corrección de sus datos personales que considere inexactos, desactualizados o incompletos.</Text>
              </Space>
            </Col>
          </Row>

          <Row style={{ marginLeft: 15 }}>
            <Col span={24}>
              <Space align="start" size={5}>
                <label className='pointerSeparator'>■</label>
                <Text className='textoCuerpoTerminos'>Derecho a pedir la cancelación, borrado o eliminación de datos personales: el Usuario tiene derecho a solicitar la eliminación de sus datos personales en los siguientes casos:</Text>
              </Space>
            </Col>
          </Row>


          <Row style={{ marginLeft: 40 }}>
            <Col span={24}>
              <Space align="start" size={5}>
                <label className='textoCuerpoTerminos countRomano'>i.</label>
                <Text className='textoCuerpoTerminos'>Cuando los datos no resulten necesarios en relación con los fines del tratamiento para el cual fueron recogidos.</Text>
              </Space>
            </Col>
          </Row>
          <Row style={{ marginLeft: 40 }}>
            <Col span={24}>
              <Space align="start" size={5}>
                <label className='textoCuerpoTerminos countRomano'>ii.</label>
                <Text className='textoCuerpoTerminos'>Cuando el Usuario haya revocado su consentimiento para el tratamiento y éste no tenga otro fundamento legal.</Text>
              </Space>
            </Col>
          </Row>
          <Row style={{ marginLeft: 40 }}>
            <Col span={24}>
              <Space align="start" size={5}>
                <label className='textoCuerpoTerminos countRomano'>iii.</label>
                <Text className='textoCuerpoTerminos'>Cuando los datos hayan sido obtenidos o tratados ilícitamente por HBR.</Text>
              </Space>
            </Col>
          </Row>
          <Row style={{ marginLeft: 40 }}>
            <Col span={24}>
              <Space align="start" size={5}>
                <label className='textoCuerpoTerminos countRomano'>iv.</label>
                <Text className='textoCuerpoTerminos'>Cuando se trate de datos caducos.</Text>
              </Space>
            </Col>
          </Row>
          <Row style={{ marginLeft: 40 }}>
            <Col span={24}>
              <Space align="start" size={5}>
                <label className='textoCuerpoTerminos countRomano'>v.</label>
                <Text className='textoCuerpoTerminos'>Cuando los datos deban suprimirse para el cumplimiento de una sentencia judicial o de una obligación legal.</Text>
              </Space>
            </Col>
          </Row>
          <Row style={{ marginLeft: 15 }}>
            <Col span={24}>
              <Space align="start" size={5}>
                <label className='pointerSeparator'>■</label>
                <Text className='textoCuerpoTerminos'>Derecho a pedir la limitación o restricción del procesamiento de datos personales: el Usuario tiene derecho a solicitar la limitación del tratamiento de sus datos personales, por ejemplo, a sólo determinados fines.</Text>
              </Space>
            </Col>
          </Row>
          <Row style={{ marginLeft: 15 }}>
            <Col span={24}>
              <Space align="start" size={5}>
                <label className='pointerSeparator'>■</label>
                <Text className='textoCuerpoTerminos'>Derecho a oponerse u objetar el procesamiento de datos personales: el Usuario tiene derecho a oponerse a dicho procesamiento, en cualquier momento, si:</Text>
              </Space>
            </Col>
          </Row>

          <Row style={{ marginLeft: 40 }}>
            <Col span={24}>
              <Space align="start" size={5}>
                <label className='textoCuerpoTerminos countRomano'>i.</label>
                <Text className='textoCuerpoTerminos'>El tratamiento afecta sus derechos y libertades fundamentales.</Text>
              </Space>
            </Col>
          </Row>
          <Row style={{ marginLeft: 40 }}>
            <Col span={24}>
              <Space align="start" size={5}>
                <label className='textoCuerpoTerminos countRomano'>ii.</label>
                <Text className='textoCuerpoTerminos'>El tratamiento se realiza exclusivamente con fines de mercadotecnia o marketing directo de bienes, productos o servicios.</Text>
              </Space>
            </Col>
          </Row>
          <Row style={{ marginLeft: 15 }}>
            <Col span={24}>
              <Space align="start" size={5}>
                <label className='pointerSeparator'>■</label>
                <Text className='textoCuerpoTerminos'>Derecho a retirar cualquiera de los consentimientos que haya otorgado, sin que ello afecte la licitud del tratamiento basado en el consentimiento previo a su retirada</Text>
              </Space>
            </Col>
          </Row>
          <br />
          <Text className='textoCuerpoTerminos'>Si desea ejercer alguno de los derechos mencionados anteriormente comuníquese directamente en la sección “Contacto” en el Sitio web.</Text>
          <br />
          <br />
          <Text className='textoCuerpoTerminos'>Es probable que el Usuario que solicite algunas de estas acciones no pueda volver a usar el Sitio web con todas sus funcionalidades.</Text>
          <br />
          <br />
          <Text className='textoCuerpoTerminos'>Los datos personales se conservarán hasta que se cumpla el objetivo por el cual se recopilaron o hasta que el Usuario ejerza el derecho a la rectificación, cancelación y/u oposición de estos. Esto se cumplirá en la medida que no se entre en conflicto con la necesidad de mantener su almacenamiento, por motivos legales.</Text>
          <Title level={4} className='tituloTerminos'>7. Base legal para el procesamiento</Title>
          <Text className='textoCuerpoTerminos'>El procesamiento de los datos personales es necesario para el cumplimiento de las obligaciones contractuales que podría tener HBR hacia Usted en caso de ser aplicable, para responder a sus consultas, comentarios, sugerencias, reclamos u otros similares, para proteger los intereses legítimos de la Empresa y para cumplir con nuestras obligaciones legales.</Text>
          <br />
          <br />
          <Text className='textoCuerpoTerminos'>Cuando HBR dependa de su consentimiento para procesar sus datos personales, Usted tiene derecho a rechazar el consentimiento en cualquier momento. Sin embargo, en caso de que la Empresa haya compartido sus datos personales personal en función de su consentimiento, para retirar su consentimiento deberá dirigirse a la entidad con la que sus datos fueron compartidos.</Text>
          <br />
          <br />
          <Text className='textoCuerpoTerminos'>Si tiene alguna pregunta o necesita más información sobre la base legal sobre la que recopilamos y usamos su información personal, comuníquese con nosotros usando nuestra página Contacto en el Sitio web.</Text>
          <Title level={4} className='tituloTerminos'>8. Seguridad y confidencialidad</Title>
          <Text className='textoCuerpoTerminos'>HBR trata sus datos personales con estricta confidencialidad, y el servidor en el que se almacenarán estos datos incluye las medidas de seguridad estándar de la industria necesarias para evitar la destrucción, pérdida, alteración, divulgación o acceso no autorizados, accidentales o ilegales.</Text>
          <br />
          <br />
          <Text className='textoCuerpoTerminos'>En particular, HBR dispone de las siguientes herramientas y prácticas para procesar y almacenar los datos personales de los Usuarios de manera segura, los cuales lleva a cabo y verifica su servicio de hosting, a través de:</Text>
          <Row style={{ marginLeft: 15 }}>
            <Col span={24}>
              <Space align="start" size={5}>
                <label className='pointerSeparator'>■</label>
                <Text className='textoCuerpoTerminos'>Análisis de Ethical Hacking</Text>
              </Space>
            </Col>
          </Row>
          <Row style={{ marginLeft: 15 }}>
            <Col span={24}>
              <Space align="start" size={5}>
                <label className='pointerSeparator'>■</label>
                <Text className='textoCuerpoTerminos'>Mecanismos protectores de ataques DDoS</Text>
              </Space>
            </Col>
          </Row>
          <Row style={{ marginLeft: 15 }}>
            <Col span={24}>
              <Space align="start" size={5}>
                <label className='pointerSeparator'>■</label>
                <Text className='textoCuerpoTerminos'>Buenas prácticas de programación</Text>
              </Space>
            </Col>
          </Row>
          <Row style={{ marginLeft: 15 }}>
            <Col span={24}>
              <Space align="start" size={5}>
                <label className='pointerSeparator'>■</label>
                <Text className='textoCuerpoTerminos'>Certificados SSL</Text>
              </Space>
            </Col>
          </Row>
          <br />
          <Title level={4} className='tituloTerminos'>9. Enlaces a otros sitios web</Title>
          <Text className='textoCuerpoTerminos'>Esta Política de Privacidad se aplica solo al Sitio web y a los productos y/o servicios desarrollados por la HBR y que Usted contrate y acceda a través del Sitio web. La Política de Privacidad no se aplica a sitios web de terceros vinculados al Sitio web. Los sitios web o servicios de terceros pueden tener sus propias políticas de privacidad y le recomendamos que las lea detenidamente. La inclusión de un enlace a un sitio web o servicio de un tercero no es una recomendación del uso de dicho sitio web o servicio. Los sitios de terceros pueden contener información o servicios que son ilegales o que algunas personas pueden encontrar inapropiados u ofensivos. Usted reconoce y acepta que no tendremos responsabilidad alguna con respecto a dichos sitios de terceros y su uso de ellos.</Text>
          <Title level={4} className='tituloTerminos'>10. Asignación</Title>
          <Text className='textoCuerpoTerminos'>Sujeto a la ley aplicable, HBR podrá transferir o ceder su información, sin previo aviso y sin necesidad de recibir su consentimiento, a cualquier tercero como resultado de un cambio de control, consolidación, fusión, adquisición o cualquier proceso de reorganización, local y/o transfronterizo.</Text>
          <Title level={4} className='tituloTerminos'>11.  Actualizaciones de esta política</Title>
          <Text className='textoCuerpoTerminos'>Esta Política de Privacidad está sujeta a modificaciones. Consulte el título "Última revisión" en este documento para ver la fecha en la cual se actualizó por última vez. Cualquier cambio a la Política de Privacidad entrará en vigencia una vez que sea publicada en el Sitio web. En caso de cambios sustanciales en esta Política de Privacidad, HBR lo comunicará mediante un aviso en el Sitio web. La referida modificación a esta Política de Privacidad sólo entrará en vigencia si usted acepta explícitamente la nueva Política de Privacidad a través del Sitio Web. Si el usuario no está de acuerdo con los cambios, deberá dejar de utilizar el Sitio Web.</Text>
          <br />
          <br />
          <Text className='textoCuerpoTerminos'>Última revisión: 12 de Julio de 2024.</Text>
        </div>
      </div>
    </Modal>
  )
}

export default ModalTerminosCondiciones