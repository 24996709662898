import React from 'react'
import { Card, Row, Col, Space, Button, Typography, Flex } from 'antd'
import { CheckOutlined, SafetyCertificateOutlined } from '@ant-design/icons';
import { onRedirectToProducto } from '../../services/helpers/stringHelper';
import LogoPlan from '../detalle-producto-persona/LogoPlan';
import { ShieldDone } from 'react-iconly';


const { Text } = Typography
const ProductoInnovationLabEmpresa = ({ plan }) => {
    return (
        <>
            {/*<Card className='card-detail-prod' bordered={false} style={{ background: '#3D3D3D' }}>
            <Flex style={{ color: '#FFFBFA', flex: 1 }}>
                <Row justify="space-between" >
                    <Col span={24}>
                        <Space>
                            <LogoPlan nombrePlan={plan?.nombrePlan}></LogoPlan>
                            <Row>
                                <Col span={24}>
                                    <p style={{
                                        fontWeight: 'bold', textAlign: 'justify', marginBottom: 0,
                                        fontSize: 24,
                                        color: '#FFFBFA', fontFamily: 'Degular'
                                    }}>{plan?.nombrePlan}</p>
                                </Col>
                                <Col span={24}>
                                    <p style={{ fontSize: 12, color: '#FFFBFA', marginTop: 0, fontFamily: 'Degular' }}>Innovation lab</p>
                                </Col>
                            </Row>

                        </Space>
                    </Col>
                </Row>
                
                    <p style={{ textAlign: 'justify', fontWeight: 'bold', fontSize: 24, fontFamily: 'Degular', marginTop: 16, marginBottom: 12 }}>Este plan incluye</p>
                    <Row gutter={0} style={{ marginTop: 16 }}>
                        <Col xs={2} sm={2}>
                            <ShieldDone style={{ color: '#FF411C' }} />
                        </Col>
                        <Col xs={22} sm={22}>
                            <Text style={{ fontFamily: 'Degular', fontSize: 16, fontWeight: 'bold', color: '#FFFBFA' }}>Tipo plan: </Text>
                            <Text style={{ fontFamily: 'Degular', fontSize: 16, color: '#FFFBFA' }}>{plan.descripcionPlanDashboard}</Text>
                        </Col>
                    </Row>
                    <Row gutter={0} style={{ marginTop: 16 }}>
                        <Col xs={2} sm={2}>
                            <ShieldDone style={{ color: '#FF411C' }} />
                        </Col>
                        <Col xs={22} sm={22}>
                            <Text style={{ fontFamily: 'Degular', fontSize: 16, fontWeight: 'bold', color: '#FFFBFA' }}>Usuarios: </Text>
                            <Text style={{ fontFamily: 'Degular', fontSize: 16, color: '#FFFBFA' }}>{plan.usuariosSugeridosInnovationLab}</Text>
                        </Col>
                    </Row>
                    <Row gutter={0} style={{ marginTop: 16 }}>
                        <Col xs={2} sm={2}>
                            <ShieldDone style={{ color: '#FF411C' }} />
                        </Col>
                        <Col xs={22} sm={22}>
                            <Text style={{ fontFamily: 'Degular', fontSize: 16, fontWeight: 'bold', color: '#FFFBFA' }}> Memoria Ram: </Text>
                            <Text style={{ fontFamily: 'Degular', fontSize: 16, color: '#FFFBFA' }}>{plan?.memoriaRam} GB</Text>
                        </Col>
                    </Row>
                    <Row style={{ marginTop: 16, marginBottom: 16 }}>
                        <Col xs={2} sm={2}>
                            <ShieldDone style={{ color: '#FF411C' }} />
                        </Col>
                        <Col xs={22} sm={22}>
                            <Text style={{ fontFamily: 'Degular', fontSize: 16, fontWeight: 'bold', color: '#FFFBFA' }}>vCores: </Text>
                            <Text style={{ fontFamily: 'Degular', fontSize: 16, color: '#FFFBFA' }}>{plan?.cantidadNucleos} núcleos</Text>
                        </Col>
                    </Row>
                </Flex>
                <Flex style={{ display: 'flex', justifyContent: 'center' }}>
                    {plan.nombrePlan === 'Workspace S' ? (
                        <Space direction='vertical' style={{ width: '100%', marginTop: 10 }}>
                            {plan?.nombrePlan === 'Básico' ? (
                                <Button
                                    onClick={() => onRedirectToProducto(plan.idPlanProducto, true)}
                                    className='btn-onboarding-empresa'
                                    style={{ width: '100%' }}
                                >
                                    Prueba gratis
                                </Button>
                            ) :
                                (
                                    <Button
                                        onClick={() => onRedirectToProducto(plan.idPlanProducto, false)}
                                        className='btn-onboarding-empresa'
                                        style={{ width: '100%' }}
                                    >
                                        Solicitar workspace
                                    </Button>
                                )}
                        </Space>
                    ) : (
                        <Space direction='vertical' style={{ width: '100%', marginTop: 10 }}>
                            {plan?.nombrePlan === 'Básico' ? (
                                <Button
                                    onClick={() => onRedirectToProducto(plan.idPlanProducto, true)}
                                    className='btn-onboarding-empresa'
                                    style={{ width: '100%' }}
                                >
                                    Prueba gratis
                                </Button>
                            ) : (
                                <Button
                                    onClick={() => onRedirectToProducto(plan.idPlanProducto, false)}
                                    className='btn-onboarding-empresa'
                                    style={{ width: '100%' }}
                                >
                                    Solicitar workspace
                                </Button>)}
                        </Space>
                    )}
                </Flex>


            </Card>*/}
            <Card
            className='card-detail-prod'
            bordered={false}
            style={{
                background: '#3D3D3D',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'space-between',
                
            }}
            >
            <div style={{ flex: 1 }}>
             
                <Row justify="start" style={{ marginBottom: 16 }}>
                <Col>
                    <Space>
                    <LogoPlan nombrePlan={plan?.nombrePlan} />
                    <div>
                        <p
                        style={{
                            fontWeight: 'bold',
                            fontSize: 24,
                            color: '#FFFBFA',
                            fontFamily: 'Degular',
                            marginBottom: 0,
                        }}
                        >
                        {plan?.nombrePlan}
                        </p>
                        <p
                        style={{
                            fontSize: 12,
                            color: '#FFFBFA',
                            fontFamily: 'Degular',
                            marginTop: 0,
                        }}
                        >
                        Innovation lab
                        </p>
                    </div>
                    </Space>
                </Col>
                </Row>

        
                <div style={{ marginBottom: 16 }}>
                <p
                    style={{
                    textAlign: 'justify',
                    fontWeight: 'bold',
                    fontSize: 24,
                    fontFamily: 'Degular',
                    marginBottom: 12,
                    color: '#FFFBFA',
                    }}
                >
                    Este plan incluye
                </p>
                <Row gutter={[8, 8]} style={{ marginBottom: 16 }}>
                    <Col xs={2} sm={2}>
                        <ShieldDone style={{ color: '#FF411C' }} />
                    </Col>
                    <Col xs={22} sm={22}>
                        <Text
                            style={{
                            fontFamily: 'Degular',
                            fontSize: 16,
                            fontWeight: 'bold',
                            color: '#FFFBFA',
                            }}
                        >
                            Tipo plan:
                        </Text>{' '}
                        <Text style={{ fontFamily: 'Degular', fontSize: 16, color: '#FFFBFA' }}>
                            {plan.descripcionPlanDashboard}
                        </Text>
                    </Col>
                    <Col xs={2} sm={2}>
                            <ShieldDone style={{ color: '#FF411C' }} />
                    </Col>
                    <Col xs={22} sm={22}>
                            <Text style={{ fontFamily: 'Degular', fontSize: 16, fontWeight: 'bold', color: '#FFFBFA' }}>Usuarios: </Text>
                            <Text style={{ fontFamily: 'Degular', fontSize: 16, color: '#FFFBFA' }}>{plan.usuariosSugeridosInnovationLab}</Text>
                    </Col>
                   
                        <Col xs={2} sm={2}>
                            <ShieldDone style={{ color: '#FF411C' }} />
                        </Col>
                        <Col xs={22} sm={22}>
                            <Text style={{ fontFamily: 'Degular', fontSize: 16, fontWeight: 'bold', color: '#FFFBFA' }}> Memoria Ram: </Text>
                            <Text style={{ fontFamily: 'Degular', fontSize: 16, color: '#FFFBFA' }}>{plan?.memoriaRam} GB</Text>
                        </Col>
                    
                        <Col xs={2} sm={2}>
                            <ShieldDone style={{ color: '#FF411C' }} />
                        </Col>
                        <Col xs={22} sm={22}>
                            <Text style={{ fontFamily: 'Degular', fontSize: 16, fontWeight: 'bold', color: '#FFFBFA' }}>vCores: </Text>
                            <Text style={{ fontFamily: 'Degular', fontSize: 16, color: '#FFFBFA' }}>{plan?.cantidadNucleos} núcleos</Text>
                        </Col>
                   
                    </Row>
                </div>
            </div>

            
            <div style={{ display: 'flex', justifyContent: 'center', marginTop: 'auto' }}>
                {plan.nombrePlan === 'Workspace S' ? (
                <Space direction='vertical' style={{ width: '100%' }}>
                    {plan?.nombrePlan === 'Básico' ? (
                    <Button
                        onClick={() => onRedirectToProducto(plan.idPlanProducto, true)}
                        className='btn-onboarding-empresa'
                        style={{ width: '100%' }}
                    >
                        Prueba gratis
                    </Button>
                    ) : (
                    <Button
                        onClick={() => onRedirectToProducto(plan.idPlanProducto, false)}
                        className='btn-onboarding-empresa'
                        style={{ width: '100%' }}
                    >
                        Solicitar workspace
                    </Button>
                    )}
                </Space>
                ) : (
                <Space direction='vertical' style={{ width: '100%' }}>
                    {plan?.nombrePlan === 'Básico' ? (
                    <Button
                        onClick={() => onRedirectToProducto(plan.idPlanProducto, true)}
                        className='btn-onboarding-empresa'
                        style={{ width: '100%' }}
                    >
                        Prueba gratis
                    </Button>
                    ) : (
                    <Button
                        onClick={() => onRedirectToProducto(plan.idPlanProducto, false)}
                        className='btn-onboarding-empresa'
                        style={{ width: '100%' }}
                    >
                        Solicitar workspace
                    </Button>
                    )}
                </Space>
                )}
            </div>
            </Card>

        </>
    )
}

export default ProductoInnovationLabEmpresa