import React from 'react'
import { Route, Routes } from 'react-router-dom';
import HomePage from '../pages/HomePage';
import PaisPage from "../pages/PaisPage"
import ProductoPersonaPage from '../pages/ProductoPersonaPage';
import ProductoEmpresaPage from '../pages/ProductoEmpresaPage';
import DetalleProductoPersonaPage from '../pages/DetalleProductoPersonaPage';
import DetalleProductoEmpresaPage from '../pages/DetalleProductoEmpresaPage';
import ContactoPage from '../pages/ContactoPage';



const AppRoutes = () => {
    return (
        <Routes>
          <Route path="/" element={< HomePage />} />
          <Route path="/home" element={<HomePage />} />
          <Route path="/seleccion-pais" element={<PaisPage/>}/>
          <Route path="/producto-persona" element={<ProductoPersonaPage />} />
          <Route path='/producto-empresa' element={<ProductoEmpresaPage/>}/>
          <Route path='/detalle-producto-persona/:id' element={<DetalleProductoPersonaPage />} />
          <Route path='/detalle-producto-empresa/:id' element={<DetalleProductoEmpresaPage/>}/>
          <Route path='/contacto' element={<ContactoPage/>}/>
        </Routes>
      );
}

export default AppRoutes