import { PublicAxios } from "./helpers/publicInterceptorAxios"

const urlApi = process.env.REACT_APP_URL_PUBLIC_BACKEND

export const generarSolicitudContacto = async (data) => {
  return PublicAxios.post(`${urlApi}/api/api-externa/contacto`, data)
    .then((res) => {
      return res.data;
    })
};

export const productosApi = async (idTipoProducto1, idTipoProducto2, idTipoProducto3, idTipoProducto4) => {
  const queryParams = `idsTipoProducto=${idTipoProducto1},${idTipoProducto2},${idTipoProducto3}, ${idTipoProducto4}`;
  return PublicAxios.get(`${urlApi}/api/api-externa/producto?${queryParams}`)
    .then((res) => {
      return res.data;
    })
};

export const detalleProductosApi = async (idProducto, idTipoCliente) => {
  return PublicAxios.get(`${urlApi}/api/api-externa/detalle-producto/${idProducto}/${idTipoCliente}`).then((res) => { return res.data })
}

/**
 * Obtiene un documento de un producto según su tipo
 * @param {number} idProduto - identificador del producto
 * @param {number} idTipoDocumento - identificador del tipo de documento del producto. 1-> Contrato de producto; 2->Folleto producto; 3-> Documentacion
 * @returns {Promise<any>} Archivo en formato "arrayBuffer"
 */
export const getDocumento = async (idProducto, idTipoDocumento) => {
  try {
    const response = await PublicAxios.get(
      `${urlApi}/api/api-externa/producto/documento/${idProducto}/${idTipoDocumento}`,
      { responseType: 'arraybuffer' }
    );

    const contentDisposition = response.headers["content-disposition"];
    let startFileNameIndex = contentDisposition.indexOf('"') + 1;
    let endFileNameIndex = contentDisposition.lastIndexOf('"');
    let filename = contentDisposition.substring(startFileNameIndex, endFileNameIndex);

    return {
      buffer: response.data,
      contentType: response.headers["content-type"],
      fileName: filename
    };
  } catch (error) {
    // Manejar errores aquí
    return Promise.reject(error);
  }
};

export const getTerminosCondicionesPortal = async () => {
  try {
    return PublicAxios
      .get(
        `${urlApi}/api/api-externa/terminosYcondiciones`,
        { responseType: 'arraybuffer' }
      )
      .then((res) => {
        return {
          buffer: res.data,
          fileName: 'Terminos y condiciones.pdf'
        }
      })
  }
  catch (error) {
    return Promise.reject(error)
  }
}

export const getGuiaInicioPortal = async (idTipoCliente) => {
  try {
    return PublicAxios
    .get(
      `${urlApi}/api/api-externa/guiaInicio/${idTipoCliente}`, 
      { responseType: 'arraybuffer' }
    )
    .then((res) => {
      return{
        buffer: res.data,
        fileName: 'Guia de inicio.pdf'
      }
    })
  }
  catch(error) {
    return Promise.reject(error)
  }
}

