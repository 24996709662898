import React from 'react'
import HeaderHome from '../Components/layout/Header'
import Contacto from '../Components/contacto/Contacto'
import FooterProd from '../Components/layout/FooterProd'

const ContactoPage = () => {
  return (
    <>
        <HeaderHome/>
        <Contacto/>
        <FooterProd/>
    </>
  )
}

export default ContactoPage