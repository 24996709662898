import React from 'react'
import HeaderHome from '../Components/layout/Header'
import FooterHome from '../Components/layout/Footer'
import SeleccionarPais from '../Components/pais/SeleccionarPais'


const PaisPage = () => {
  return (
    <>
        <HeaderHome/>
        <SeleccionarPais/>
        <FooterHome/>
    </>
  )
}

export default PaisPage