import React from 'react'
import BannerProductoPersona from './BannerProductoPersona'
import CaracteristicasProductoPersona from './CaracteristicasProductoPersona'
import ContratarProductoPersona from './ContratarProductoPersona'
import FooterProd from '../layout/FooterProd'
import ProductosPersona from './ProductosPersona'
import PreguntasFrecuentesProductoPersona from './PreguntasFrecuentesProductoPersona'

const Index = () => {
  return (
    <>
      <BannerProductoPersona />
      <div style={{ backgroundColor: '#FFFBFA', overflowX: 'hidden' }}>
        <CaracteristicasProductoPersona />
        <ContratarProductoPersona />
        <ProductosPersona />
        <PreguntasFrecuentesProductoPersona />
      </div>
    </>
  )
}

export default Index