import React from 'react'
import BannerProductoEmpresa from './BannerProductoEmpresa'
import CaracteristicasProductoEmpresa from './CaracteristicasProductoEmpresa'
import ContratarProductosEmpresa from './ContratarProductosEmpresa'
import ProductosEmpresa from './ProductosEmpresa'
import PreguntasFrecuentesProductoEmpresa from './PreguntasFrecuentesProductoEmpresa'

const Index = () => {
  return (
    <>
      <BannerProductoEmpresa />
      <div style={{ backgroundColor: '#FFFBFA', overflowX: 'hidden' }}>
        <CaracteristicasProductoEmpresa />
        <ContratarProductosEmpresa />
        <ProductosEmpresa />
        <PreguntasFrecuentesProductoEmpresa />
      </div>
    </>
  )
}

export default Index