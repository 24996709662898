import React, { createContext, useContext, useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';

const MenuContext = createContext();

export const MenuProvider = ({ children }) => {
  const [selectedPath, setSelectedPath] = useState('');
  const location = useLocation();

  useEffect(() => {
    setSelectedPath(location.pathname);
  }, [location]);

  const value = {
    selectedPath,
    setSelectedPath
  };

  return (
    <MenuContext.Provider value={value}>
      {children}
    </MenuContext.Provider>
  );
};

export const useMenu = () => {
  const context = useContext(MenuContext);
  if (context === undefined) {
    throw new Error('useMenu debe ser usado dentro de un MenuProvider');
  }
  return context;
};