import { ApiOutlined, CrownOutlined, RocketOutlined } from '@ant-design/icons'

const LogoPlan = ({ nombrePlan }) => {
    return (
        <div style={{ marginTop: 10 }}>
            {nombrePlan?.toLowerCase()?.trim() === 'starter' ?
                <RocketOutlined className="colorTipoPlan" style={{ fontSize: '38px', color: '#fff' }} /> :
                nombrePlan?.toLowerCase()?.trim() === 'developer' ?
                    <ApiOutlined className="colorTipoPlan" style={{ fontSize: '38px', color: '#fff' }} /> :
                    nombrePlan?.toLowerCase()?.trim() === 'advance' || nombrePlan?.toLowerCase()?.trim() === 'advanced' ?
                        <CrownOutlined className="colorTipoPlan" style={{ fontSize: '38px', color: '#fff' }} /> :
                        //default
                        <RocketOutlined className="colorTipoPlan" style={{ fontSize: '38px', color: '#fff' }} />
            }
        </div>
    );
}

export default LogoPlan