import { ChevronDown, ChevronUp } from 'react-iconly'
import { Card, Col, Flex, Row, Typography } from 'antd';
import React, { useState } from 'react'
import { Link } from 'react-router-dom';

const { Text } = Typography;
const CardPreguntas = () => {

    const [isDownloadDocumentacion, setIsDownloadDocumentacion] = useState(false)

    const onDownloadGuiaInicioPersona = async (idTipoCliente) => {
        setIsDownloadDocumentacion(true)
        try {
          const docRes = await 'getGuiaInicioPortal(idTipoCliente)'
    
          if (docRes) {
            const blob = new Blob([docRes.buffer], { type: 'application/pdf' });
            const linkRedirect = URL.createObjectURL(blob);
    
            window.open(linkRedirect, "_blank");
          } else {
            console.log("Respuesta nula")
          }
        } catch (error) {
          console.error("Error al descargar documento")
          console.error(error)
        } finally {
          setIsDownloadDocumentacion(false)
        }
      }

    const [expanded, setExpanded] = useState({
        1: false,
        2: false,
        3: false,
        4: false,
        5: false,
        6: false,
        7: false,
        8: false,
    });

    const toggleExpanded = (index) => {
        setExpanded((prevExpanded) => ({
          ...prevExpanded,
          [index]: !prevExpanded[index],
        }));
    };

  return (
    <>
        <Row gutter={0} >
            <Col md={24} lg={24} style={{ width: '100vw' }}>
                <Card
                className="card-preguntas"
                onClick={() => toggleExpanded(1)}
                >
                {/* Fila para el título y el ícono */}
                <Row gutter={[20, 20]} align="middle">
                    <Col xs={21} sm={22} md={22}>
                    <Text 
                        style={{
                        color: '#FFFBFA',
                        fontWeight: 'bold',
                        fontSize: 20,
                        fontFamily: 'Degular',
                        }}
                    >
                        ¿Qué es Portal Marketplace?
                    </Text>
                    </Col>
                    <Col xs={3} sm={2} md={2} className="icon-col">
                    {expanded[1] ? (
                        <ChevronUp style={{ color: '#FF411C' }} />
                    ) : (
                        <ChevronDown style={{ color: '#FF411C' }} /> 
                    )}
                    </Col>
                </Row>

                {/* Fila para el contenido del texto */}
                <Row
                    gutter={[20, 20]}
                    style={{
                    
                    }}
                >
                    {/* Texto */}
                    <Col xs={21} sm={22} md={22}>
                    {expanded[1] && (
                        <>
                        <Text className='typography-text-c' style={{ color: '#F7F7F7', paddingTop: 20, display: 'block' }}>
                            {`Es un portal web donde usuarios pueden revisar la oferta de productos digitales que tiene la Bolsa de Santiago a disposición del mercado bursátil y financiero.`}
                        </Text>
                        <Text
                            style={{ display: 'block', color: '#F7F7F7', paddingTop: 20 }}
                            className='typography-text-c'
                        >
                            {`Dentro de una gran variedad de funcionalidades que tiene este portal, se pueden contratar productos de manera rápida y sencilla, como también autogestionar suscripciones y darse de baja cuando lo deseen, todo en un mismo sitio.`}
                        </Text>
                        <Text
                            style={{ display: 'block',  color: '#F7F7F7', paddingTop: 20 }}
                            className='typography-text-c'
                        >
                            {`Por el momento los productos que se ofrecen son limitados, en la sección Productos puedes revisar cuáles son. Estamos trabajando para poder continuar incorporando más productos en el futuro de manera que gran parte de los servicios que tiene la Bolsa y nuam sean contratados y gestionados a través de este portal.`}
                        </Text>
                        </>
                    )}
                    </Col>
                    {/* Espacio vacío */}
                    <Col xs={3} sm={22} md={2}></Col>
                </Row>
                </Card>

            </Col>
        </Row>

        <Row style={{ marginTop: 10 }}>
            <Col md={24} lg={24} style={{ width: '100vw' }}>
                <Card
                className="card-preguntas"
                onClick={() => toggleExpanded(2)}
                >
                {/* Fila para el título y el ícono */}
                <Row gutter={[20, 20]} align="middle">
                    <Col xs={21} sm={22} md={22}>
                    <Text
                        style={{
                        color: '#FFFBFA',
                        fontWeight: 'bold',
                        fontSize: 20,
                        fontFamily: 'Degular',
                        }}
                    >
                        ¿Quién puede contratar productos?
                    </Text>
                    </Col>
                    <Col xs={3} sm={2} md={2} className="icon-col" >
                    {expanded[2] ? (
                        <ChevronUp style={{ color: '#FF411C' }} />
                    ) : (
                        <ChevronDown style={{ color: '#FF411C' }} />
                    )}
                    </Col>
                </Row>

                {/* Fila para el contenido del texto */}
                <Row
                    gutter={[20, 20]}
                    style={{
                    
                    }}
                >
                    {/* Texto */}
                    <Col xs={21} sm={22} md={22}>
                    {expanded[2] && (
                        <>
                        <Text className='typography-text-c' style={{ color: '#F7F7F7', paddingTop: 20, display: 'block' }}>
                        {`Actualmente, Marketplace está dirigido a usuarios de Chile. Solo pueden crear una cuenta y contratar productos personas naturales con cédula chilena vigente y empresas que tengan RUT vigente.`}
                        </Text>
                        </>
                    )}
                    </Col>
                    {/* Espacio vacío */}
                    <Col xs={3} sm={2} md={2} style={{ textAlign: 'center' }}></Col>
                </Row>
                </Card>

            </Col>
        </Row>

        <Row style={{ marginTop: 10 }}>
            <Col md={24} lg={24} style={{ width: '100vw' }}>
                <Card
                className="card-preguntas"
                onClick={() => toggleExpanded(3)}
                >
                {/* Fila para el título y el ícono */}
                <Row gutter={[20, 20]} align="middle">
                    <Col xs={21} sm={22} md={22}>
                    <Text
                        style={{
                        color: '#FFFBFA',
                        fontWeight: 'bold',
                        fontSize: 20,
                        fontFamily: 'Degular',
                        }}
                    >
                        ¿Qué necesito para poder crear una cuenta Persona?
                    </Text>
                    </Col>
                    <Col xs={3} sm={2} md={2} className="icon-col">
                    {expanded[3] ? (
                        <ChevronUp style={{ color: '#FF411C' }} />
                    ) : (
                        <ChevronDown style={{ color: '#FF411C' }} />
                    )}
                    </Col>
                </Row>

                {/* Fila para el contenido del texto */}
                <Row
                    gutter={[20, 20]}
                    style={{
                    
                    }}
                >
                    {/* Texto */}
                    <Col xs={21} sm={22} md={22}>
                    {expanded[3] && (
                        <>
                        <Text className='typography-text-c' style={{ color: '#F7F7F7', paddingTop: 20, display: 'block' }}>
                        {`Para poder crear una cuenta como persona natural solo necesitas completar los datos solicitados en el formulario de registro del portal.`}
                        </Text>
                        </>
                    )}
                    </Col>
                    {/* Espacio vacío */}
                    <Col xs={3} sm={2} md={2} style={{ textAlign: 'center' }}></Col>
                </Row>
                </Card>

            </Col>
        </Row>
       
        <Row style={{ marginTop: 10 }}>
            <Col md={24} lg={24} style={{ width: '100vw' }}>
                <Card
                className="card-preguntas"
                onClick={() => toggleExpanded(9)}
                >
                {/* Fila para el título y el ícono */}
                <Row gutter={[20, 20]} align="middle">
                    <Col xs={21} sm={22} md={22}>
                    <Text
                        style={{
                        color: '#FFFBFA',
                        fontWeight: 'bold',
                        fontSize: 20,
                        fontFamily: 'Degular',
                        }}
                    >
                        ¿Qué necesito para poder crear una cuenta Empresa?
                    </Text>
                    </Col>
                    <Col xs={3} sm={2} md={2} className="icon-col">
                    {expanded[9] ? (
                        <ChevronUp style={{ color: '#FF411C' }} />
                    ) : (
                        <ChevronDown style={{ color: '#FF411C' }} />
                    )}
                    </Col>
                </Row>

                {/* Fila para el contenido del texto */}
                <Row
                    gutter={[20, 20]}
                    style={{
                    
                    }}
                >
                    {/* Texto */}
                    <Col xs={21} sm={22} md={22}>
                    {expanded[9] && (
                        <>
                                    <span className='typography-text-c' style={{ color: '#F7F7F7', paddingTop: 20, display: 'block' }}>
                                      {`Para poder crear una cuenta como empresa primero necesitas completar los datos solicitados en el formulario de registro del portal. Dentro de los datos solicitados, se requiere que se suba un documento con el e-RUT de la empresa, el cual se obtiene a través del sitio web de Servicios de Impuestos Internos, esto con el fin de poder validar la veracidad de los datos entregados.`}
                                      </span>
                                      <span className='typography-text-c' style={{ display: 'block', marginTop: 20, color: '#F7F7F7' }}>
                                      {`Posterior a completar toda la información solicitada, debido a procesos internos se realizará la validación del perfil de la empresa el cual no debería demorar más de 48 horas hábiles. Una vez se apruebe correctamente, la empresa queda habilitada para contratar productos.`}
                                      </span>
                                      <span className='typography-text-c' style={{ display: 'block', marginTop: 20, color: '#F7F7F7' }}>
                                      {`En este caso sugerimos que aquel que esté creando la cuenta y que será el administrador de la empresa en el portal, sea una persona con acceso a los medios de pago de la empresa.`}
                                      </span>
                        </>
                    )}
                    </Col>
                    {/* Espacio vacío */}
                    <Col xs={3} sm={2} md={2} style={{ textAlign: 'center' }}></Col>
                </Row>
                </Card>

            </Col>
        </Row>
        
        <Row style={{ marginTop: 10 }}>
            <Col md={24} lg={24} style={{ width: '100vw' }}>
                <Card
                className="card-preguntas"
                onClick={() => toggleExpanded(4)}
                >
                {/* Fila para el título y el ícono */}
                <Row gutter={[20, 20]} align="middle">
                    <Col xs={21} sm={22} md={22}>
                    <Text
                        style={{
                        color: '#FFFBFA',
                        fontWeight: 'bold',
                        fontSize: 20,
                        fontFamily: 'Degular',
                        }}
                    >
                        ¿Cómo puedo contratar productos?
                    </Text>
                    </Col>
                    <Col xs={3} sm={2} md={2} className="icon-col">
                    {expanded[4] ? (
                        <ChevronUp style={{ color: '#FF411C' }} />
                    ) : (
                        <ChevronDown style={{ color: '#FF411C' }} />
                    )}
                    </Col>
                </Row>

                {/* Fila para el contenido del texto */}
                <Row
                    gutter={[20, 20]}
                    style={{
                    
                    }}
                >
                    {/* Texto */}
                    <Col xs={21} sm={22} md={22}>
                    {expanded[4] && (
                        <>
                        <Text className='typography-text-c'  style={{ color: '#F7F7F7', paddingTop: 20, display: 'block' }}>
                        {`Si eres una persona natural, primero debes haber creado una cuenta, una vez la hayas activado podrás ingresar con tus credenciales al Portal Persona y desde ahí en la sección Catálogo de productos contratar aquellos que más te interesen.`}
                        </Text>
                        <Text className='typography-text-c' style={{ color: '#F7F7F7', paddingTop: 20, display: 'block' }}
                        
                        >
                           {`Por otro lado, si correspondes a una empresa, primero debes haber creado una cuenta, una vez hayamos validado internamente de manera exitosa el perfil de la empresa, como administrador podrás ingresar con tus credenciales al Portal Empresa y desde ahí en la sección Catálogo de productos contratar aquellos que más le interesen a tu institución y gestionar qué usuarios pueden tener acceso a las suscripciones.`}
                        </Text>
                        <span className='typography-text-c' style={{ display: 'block', paddingTop: 20, color: '#F7F7F7' }}>
                                    {`A continuación, te dejamos una `}
                                    <Link to="#" className='link-d' style={{ color: '#1AFD8F' }} onClick={() => onDownloadGuiaInicioPersona(1)}>
                                        Guía de inicio Persona
                                    </Link>
                                    {` y una `}
                                    <Link to="#" className='link-d' style={{ color: '#1AFD8F' }} onClick={() => onDownloadGuiaInicioPersona(2)}>
                                        Guía de inicio Empresa
                                    </Link>
                                    {` que te puede ayudar con el proceso de creación de cuenta y contratación de productos.`}
                                    </span>
                        </>
                    )}
                    </Col>
                    {/* Espacio vacío */}
                    <Col xs={3} sm={2} md={2} style={{ textAlign: 'center' }}></Col>
                </Row>
                </Card>

            </Col>
        </Row>

        <Row style={{ marginTop: 10 }}>
            <Col md={24} lg={24} style={{ width: '100vw' }}>
                <Card
                className="card-preguntas"
                onClick={() => toggleExpanded(5)}
                >
                {/* Fila para el título y el ícono */}
                <Row gutter={[20, 20]} align="middle">
                    <Col xs={21} sm={22} md={22}>
                    <Text
                        style={{
                        color: '#FFFBFA',
                        fontWeight: 'bold',
                        fontSize: 20,
                        fontFamily: 'Degular',
                        }}
                    >
                        ¿Cómo es la firma de contratos?
                    </Text>
                    </Col>
                    <Col xs={3} sm={2} md={2} className="icon-col">
                    {expanded[5] ? (
                        <ChevronUp style={{ color: '#FF411C' }} />
                    ) : (
                        <ChevronDown style={{ color: '#FF411C' }} />
                    )}
                    </Col>
                </Row>

                {/* Fila para el contenido del texto */}
                <Row
                    gutter={[20, 20]}
                    style={{
                    
                    }}
                >
                    {/* Texto */}
                    <Col xs={21} sm={22} md={22}>
                    {expanded[5] && (
                        <>
                        <Text className='typography-text-c'  style={{ color: '#F7F7F7', paddingTop: 20, display: 'block' }}>
                        {`En Portal Marketplace al momento de contratar productos debes leer y aceptar los Términos y Condiciones asociados al servicio que se desea comprar para poder continuar con la contratación y habilitación del producto.`}
                        </Text>
                        <Text
                        style={{ display: 'block', color: '#F7F7F7', paddingTop: 20 }}
                            className='typography-text-c'
                        >
                            {`En la sección Productos de este sitio, si te diriges al detalle de cada producto podrás ver de manera previa el contrato (Términos y Condiciones) asociado a cada servicio.`}
                        </Text>
                        <Text
                        style={{ display: 'block', color: '#F7F7F7', paddingTop: 20 }}
                            className='typography-text-c'
                        >
                           {`Te sugerimos que si eres una Empresa descargues el documento con anterioridad y lo valides con las áreas legales correspondientes antes de continuar con la contratación.`}
                        </Text>
                        </>
                    )}
                    </Col>
                    {/* Espacio vacío */}
                    <Col xs={3} sm={2} md={2} style={{ textAlign: 'center' }}></Col>
                </Row>
                </Card>

            </Col>
        </Row>
          
        <Row style={{ marginTop: 10 }}>
            <Col md={24} lg={24} style={{ width: '100vw' }}>
                <Card
                className="card-preguntas"
                onClick={() => toggleExpanded(6)}
                >
                {/* Fila para el título y el ícono */}
                <Row gutter={[20, 20]} align="middle">
                    <Col xs={21} sm={22} md={22}>
                    <Text
                        style={{
                        color: '#FFFBFA',
                        fontWeight: 'bold',
                        fontSize: 20,
                        fontFamily: 'Degular',
                        }}
                    >
                        ¿Qué medios de pago se aceptan?
                    </Text>
                    </Col>
                    <Col xs={3} sm={2} md={2} className="icon-col">
                    {expanded[6] ? (
                        <ChevronUp style={{ color: '#FF411C' }} />
                    ) : (
                        <ChevronDown style={{ color: '#FF411C' }} />
                    )}
                    </Col>
                </Row>

                {/* Fila para el contenido del texto */}
                <Row
                    gutter={[20, 20]}
                    style={{
                    
                    }}
                >
                    {/* Texto */}
                    <Col xs={21} sm={22} md={22}>
                    {expanded[6] && (
                        <>
                        <Text className='typography-text-c' style={{ color: '#F7F7F7', paddingTop: 20, display: 'block' }}>
                        {`Los medios de pago permitidos para la contratación de productos son tarjetas de crédito, tarjetas de débito y transferencias electrónicas.`}
                        </Text>
                        <Text
                        style={{ display: 'block', color: '#F7F7F7', paddingTop: 20 }}
                            className='typography-text-c'
                        >
                           {`Los pagos deben ser realizados de manera manual mensual o anualmente (según corresponda) para poder mantener activa las suscripciones. No realizaremos cobros automáticos a tus tarjetas o cuenta bancaria.`}
                        </Text>
                        </>
                    )}
                    </Col>
                    {/* Espacio vacío */}
                    <Col xs={3} sm={2} md={2} style={{ textAlign: 'center' }}></Col>
                </Row>
                </Card>

            </Col>
        </Row>
       
        <Row style={{ marginTop: 10 }}>
            <Col md={24} lg={24} style={{ width: '100vw' }}>
                <Card
                className="card-preguntas"
                onClick={() => toggleExpanded(7)}
                >
                {/* Fila para el título y el ícono */}
                <Row gutter={[20, 20]} align="middle">
                    <Col xs={21} sm={22} md={22}>
                    <Text
                        style={{
                        color: '#FFFBFA',
                        fontWeight: 'bold',
                        fontSize: 20,
                        fontFamily: 'Degular',
                        }}
                    >
                       ¿Puedo cancelar mis suscripciones en cualquier momento?
                    </Text>
                    </Col>
                    <Col xs={3} sm={2} md={2} className="icon-col">
                    {expanded[7] ? (
                        <ChevronUp style={{ color: '#FF411C' }} />
                    ) : (
                        <ChevronDown style={{ color: '#FF411C' }} />
                    )}
                    </Col>
                </Row>

                {/* Fila para el contenido del texto */}
                <Row
                    gutter={[20, 20]}
                    style={{
                    
                    }}
                >
                    {/* Texto */}
                    <Col xs={21} sm={22} md={22}>
                    {expanded[7] && (
                        <>
                        <Text className='typography-text-c' style={{ color: '#F7F7F7', paddingTop: 20, display: 'block' }}>
                        {`Para aquellos productos de renovación automática, puedes cancelar cuando quieras tus suscripciones en Marketplace, podrás seguir utilizando el producto hasta que se cumpla el último periodo de pago o de prueba.`}
                        </Text>
                        <Text
                        style={{ display: 'block', color: '#F7F7F7', paddingTop: 20 }}
                            className='typography-text-c'
                        >
                            {`Para el resto de productos que no son de renovación automática no es necesario cancelar la suscripción, ya que automáticamente se da de baja cuando se cumple el último periodo de pago o de prueba.`}
                        </Text>
                        
                        </>
                    )}
                    </Col>
                    {/* Espacio vacío */}
                    <Col xs={3} sm={2} md={2} style={{ textAlign: 'center' }}></Col>
                </Row>
                </Card>

            </Col>
        </Row>


        
    </>
  )
}

export default CardPreguntas