
import React from 'react';
import { Button } from 'antd';

export const isImg = /^http(s)?:\/\/([\w-]+\.)+[\w-]+(\/[\w-./?%&=]*)?/;
export const getChildrenToRender = (item, i) => {
  let tag = item.name.indexOf('title') === 0 ? 'h1' : 'div';
  tag = item.href ? 'a' : tag;
  let children = typeof item.children === 'string' && item.children.match(isImg)
    ? React.createElement('img', { src: item.children, alt: 'img' })
    : item.children;
  if (item.name.indexOf('button') === 0 && typeof item.children === 'object') {
    children = React.createElement(Button, {
      ...item.children
    });
  }
  return React.createElement(tag, { key: i.toString(), ...item }, children);
};

export const separadorDeMiles = (numero) => {
  // Convierte el número a string
  let numeroString = numero.toString();
  
  // Separa la parte entera de la parte decimal (si existe)
  let partes = numeroString.split('.');
  let parteEntera = partes[0];
  let parteDecimal = partes.length > 1 ? '.' + partes[1] : '';

  // Agrega separadores de miles a la parte entera
  let separado = '';
  for (let i = parteEntera.length - 1, j = 0; i >= 0; i--, j++) {
      separado = parteEntera[i] + separado;
      if (j % 3 === 2 && i !== 0) {
          separado = '.' + separado;
      }
  }

  // Retorna el número formateado con los separadores de miles
  return separado + parteDecimal;
}

export const separadorDeMilesComas = (numero) => {
  // Convierte el número a string
  let numeroString = numero.toString();
  console.log('numeroString', numero)
  
  // Separa la parte entera de la parte decimal (si existe)
  let partes = numeroString.split('.');
  let parteEntera = partes[0];
  let parteDecimal = partes.length > 1 ? ',' + partes[1] : '';

  // Agrega separadores de miles a la parte entera
  let separado = '';
  for (let i = parteEntera.length - 1, j = 0; i >= 0; i--, j++) {
      separado = parteEntera[i] + separado;
      if (j % 3 === 2 && i !== 0) {
          separado = ',' + separado;
      }
  }

  // Retorna el número formateado con los separadores de miles
  return separado + parteDecimal;
}