import { Avatar, Button, Card, Col, Flex, Row, Typography, Space } from 'antd'
import React from 'react'
import { useNavigate } from 'react-router-dom';

import logo from '../../assets/images/Brand.svg';

const URL_LOGIN_PERSONA = process.env.REACT_APP_URL_LOGIN_PRIVADO

const { Text, Title } = Typography
const ContentInicio = () => {
    const navigate = useNavigate();

    const goToSeleccionPais = () => {
        navigate('/seleccion-pais')
        window.scrollTo(0, 0)
    };

  return (
    <Row className='row-home'>
        <Row className='' >
            <Col xs={24} sm={24} md={23} lg={12} xl={12} xxl={12}>
                <Card className='card-home'>
                    <Row gutter={[0, 20]}>
                        <Col xs={0} sm={24} md={24} lg={24} xl={24} xxl={24}>
                            <Flex justify='flex-end'>
                                <Avatar className='avatar-round' shape='round' size={19}/>
                                <Avatar className='avatar-round' shape='round' size={19}/>
                                <Avatar className='avatar-round' shape='round' size={19}/>
                            </Flex>
                        </Col>
                        <Col xs={24} sm={0} md={0} lg={0} xl={0} xxl={0}>
                            <Flex 
                                justify="space-between" 
                                align="center" 
                                className="flex-container"
                            >
                                {/* Logo */}
                                <img src={logo} alt='logo' width={120} className="logo-mobile" />

                                {/* Botones */}
                                <Space className="circles-container">
                                    <Avatar className="avatar-round" shape="round" size={19} />
                                    <Avatar className="avatar-round" shape="round" size={19} />
                                    <Avatar className="avatar-round" shape="round" size={19} />
                                </Space>
                            </Flex>
                        </Col>
                    </Row>
                    <Row gutter={[20, 20]}>
                        <Col xs={24} sm={24} md={24}>
                            <Title className='title-first' style={{ color: '#FFFBFA', fontWeight: 'bold', fontFamily: 'PT Serif' }}>¡Accede ahora!</Title>
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={22} sm={24}  md={20} lg={20} xl={20} xxl={20}>
                            <Text className='text-second' style={{ color: '#FFFBFA', fontFamily: 'PT Serif' }}>a nuestro nuevo marketplace de soluciones digitales</Text>
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={24} sm={24} md={18} lg={20} className='margenes' style={{  }}>
                            <Text className='text-thris' style={{ color: '#FFFBFA', fontFamily: 'Degular' }}>En este portal web podrás revisar la oferta de productos que tenemos para ti, contratar los que necesites y autogestionar tus propias suscripciones.</Text>
                            <br/>
                            <Text className='text-thris' style={{ color: '#FFFBFA', fontFamily: 'Degular' }}>Todo en un mismo sitio.</Text>
                        </Col>
                    </Row>
                    <Row className='row-buttons' gutter={[0, 24]}>
                        <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                            <Button
                            href={`${URL_LOGIN_PERSONA}/loginKeyCloack`}
                            className="btn-onboarding"
                            >
                                Entrar como Empresas
                            </Button>
                        </Col>
                        <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                            <Button
                            href={`${URL_LOGIN_PERSONA}/loginKeyCloack`}
                            className="btn-onboarding-persona"
                            >
                                Entrar como Personas
                            </Button>
                        </Col>
                    </Row>
                </Card>
            </Col>
        </Row>
    </Row>
  )
}

export default ContentInicio