import { LoadingOutlined } from '@ant-design/icons'
import { Col, Row, Typography } from 'antd'
import React, { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom';
import { detalleProductosApi } from '../../services/producto';
import { TipoProductoId } from '../../enum/enum';
import ProductoApiEmpresa from '../lista-producto-empresa/ProductoApiEmpresa';
import ProductoInnovationLabEmpresa from '../lista-producto-empresa/ProductoInnovationLabEmpresa';
import ProductoDashboardEmpresa from '../lista-producto-empresa/ProductoDashboardEmpresa';

const { Text } = Typography;
const ContentCardDetalleProductoEmpresa = () => {

    const { state } = useLocation();
    const [detalleProductos, setDetalleProductos] = useState({})
    const [loading, setLoading] = useState(false)

    useEffect(() => {
        const fetchData = async () => {
            try {
                setLoading(true)
                const idProducto = state ? state.productId : null;

                if (idProducto) {
                    const informacionDetalleProducto = await detalleProductosApi(idProducto, 2);
                    setDetalleProductos(informacionDetalleProducto)
                }
            } catch (error) {
                console.log(error)
                setLoading(false)
            } finally {
                setLoading(false)
            }
        }
        fetchData();

    }, [state]);

    return (

        <Row className='card-details-product'>
            <Col span={24}>
                {loading ? (
                    <div style={{ width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', minHeight: 400, minWidth: 700 }}>
                        <LoadingOutlined style={{ fontSize: 36, color: '#FF411C' }} />
                    </div>
                ) :
                    (<>
                        <Row gutter={[32, 0]} className='margin-type-plans' style={{ marginBottom: 24 }}>
                            <Col span={24}>
                                <Text style={{ marginBottom: 24 }} className='text-types-plans'>Tipos de planes disponibles</Text>
                            </Col>
                        </Row>
                        <Row gutter={20} style={{ marginTop: 32 }}>
                            {detalleProductos?.idTipoProducto === TipoProductoId.PACK_API ?
                                <>
                                    {detalleProductos.planesProducto.map((plan, index) => (
                                        <Col key={`prd-${index}`} xs={24} sm={24} md={8}>
                                            <ProductoApiEmpresa plan={plan}></ProductoApiEmpresa>
                                        </Col>
                                    ))}

                                </>
                                : detalleProductos?.idTipoProducto === TipoProductoId.INNOVATION_LAB ?
                                    <>
                                        {detalleProductos.planesProducto.map((plan, index) => (
                                            <Col key={`prd-${index}`} style={{ marginBottom: 20 }} xs={24} sm={24} md={8}>
                                                <ProductoInnovationLabEmpresa plan={plan}></ProductoInnovationLabEmpresa>
                                            </Col>
                                        ))}
                                    </>
                                    : detalleProductos?.idTipoProducto === TipoProductoId.REPORTE || detalleProductos?.idTipoProducto === TipoProductoId.PACK_REPORTE ?
                                        <>
                                            {detalleProductos.planesProducto.map((plan, index) => (
                                                <Col key={`prd-${index}`} style={{ marginBottom: 20 }} xs={24} sm={24} md={8}>
                                                    <ProductoDashboardEmpresa plan={plan} index={index}></ProductoDashboardEmpresa>
                                                </Col>
                                            ))}
                                        </>
                                        : null}
                        </Row>
                    </>
                    )}
            </Col>
        </Row>
    )
}

export default ContentCardDetalleProductoEmpresa