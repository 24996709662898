import { Button, Card, Col, Flex, Row, Typography } from 'antd'
import React, { useEffect, useState } from 'react'
import LogoProducto from '../detalle-producto-persona/LogoProducto';
import { LoadingOutlined } from '@ant-design/icons';
import { useNavigate } from 'react-router-dom';
import { productosApi } from '../../services/producto';

const { Title, Text } = Typography;

const categories = ["Todo", "Servicios de información"];
const ProductosEmpresa = () => {

  const navigate = useNavigate();
  const [selectedCategory, setSelectedCategory] = useState("Todo"); // Por defecto, seleccionar "Todo"
  const [productos, setProductos] = useState([]);
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        const apiProductos = await productosApi(1, 3, 4, 5);
        if (apiProductos) {
          const order = {
            "Dashboard": 1,
            "API Free trial": 2,
            "API Mercado de Valores": 3,
            "API Compensación y Garantías": 4,
            "Innovation Labs": 5
          };
  
          const dashboardProducts = apiProductos.filter(product => product.NombreProducto === "Dashboards");
          const innovationLabsProducts = apiProductos.filter(product => product.NombreProducto === "Innovation Labs");
  
          
          const orderedDashboardProducts = dashboardProducts.sort((a, b) => order[a.NombreProducto] - order[b.NombreProducto]);
          const orderedInnovationLabsProducts = innovationLabsProducts.sort((a, b) => order[a.NombreProducto] - order[b.NombreProducto]);
  

          const apiProducts = apiProductos.filter(product =>
            product.NombreProducto !== "Dashboards" && product.NombreProducto !== "Innovation Labs"
          );
  
          const orderedProducts = [...orderedDashboardProducts, ...apiProducts, ...orderedInnovationLabsProducts];
  
          setProductos(orderedProducts);
        }
      } catch (error) {
        console.log(error);
      } finally {
        setLoading(false);
      }
    };
    fetchData();
  }, []);

  const handleCategoryClick = (category) => {
    if (category === "Todo") {
      setSelectedCategory("Todo");
    } else {
      setSelectedCategory('Servicios de información');
    }
  }

  const filteredCards = selectedCategory === "Todo" ? productos : productos || [];

console.log("No entiendo nada");
  const handleButton = (idProducto) => {
    console.log('Entro aca???');
    navigate(`/detalle-producto-empresa/${idProducto}`, { state: { productId: idProducto  } })
    window.scrollTo(0, 0)
  };

  return (
   
      <Row gutter={[40, 0]} className='row-prod'>
        <Col xs={0} sm={0} md={24} lg={6} xl={6} xxl={6}>
        <Row>
              <Col span={24}>
                <Text className="title-prod">Productos</Text>
              </Col>
            </Row>
            <Row>
              <Col span={24}>
                <Text className="title-prod">empresas</Text>
              </Col>
            </Row>
            <Row style={{ paddingTop: 20 }}>
              <Col span={24}>
                <Text className="subtitle-prod">
                  Revisa los productos que tenemos actualmente para ti.
                </Text>
              </Col>
            </Row>
          <div className='categorias'>
            <ul style={{ listStyle: 'none', padding: 0, margin: 0 }}>
              {categories.map((category, index) => (
                <li key={index}>
                  <Button
                    type='link'
                    style={{ color: selectedCategory === category ? '#FF411C' : '#3D3D3D', fontSize: 18, padding: '28px 0 0 0', fontFamily: 'PT Serif' }}
                    className='botones-categoria btn-category-empresa'
                    onClick={() => handleCategoryClick(category)}
                  >
                    {<strong>{category}</strong>}
                  </Button>
                </li>
              ))}
            </ul>
          </div>
        </Col>
        <Col xs={24} sm={24} md={0} lg={0} xl={0} xxl={0}>
          <Row gutter={[28, 0]}>
            <Col span={24}>
              <Text className="title-prod">Productos empresas</Text>
            </Col>
          </Row>
          <Row gutter={[20, 0]} style={{ paddingTop: 28 }}>
            <Col span={24}>
              <Text className="mobile subtitle-prod">
                Revisa los productos que tenemos actualmente para ti.
              </Text>
            </Col>
          </Row>
              <div className='categorias'>
               
                  {categories.map((category, index) => (
                    
                      <Button
                        type='link'
                        style={{ color: '#3D3D3D', fontSize: 18, paddingLeft: '0', fontFamily: 'PT Serif', borderBottom: selectedCategory === category ? '2px solid #FF411C' : '', borderRadius: 0 }}
                        className='botones-categoria'
                        onClick={() => handleCategoryClick(category)}
                      >
                        <strong>{category}</strong>
                      </Button>
                   
                  ))}
               
              </div>
          </Col>
        <Col xs={24} sm={24} md={24} lg={18} xl={18} xxl={18} className='padd-prod-card'>
          <Flex>
            {loading ?
              (<div className='loader-prod' style={{ }}>
                <LoadingOutlined style={{ fontSize: 36, color: '#FF411C' }} />
              </div>) :
              (<Row className='cards-tablet'>
                {filteredCards.map((card) => (
                  <Col  xs={24} sm={24} md={12} lg={8} xl={8} xxl={8} key={card.IdProducto} className='card-tipo-producto'>
                    <Card
                      cover={<LogoProducto idTipoProducto={card.IdTipoProducto}></LogoProducto>}
                      className='cardProducto'
                    >
                     <Flex style={{ flex: 1, marginBottom: 20 }}>
                        <Col>
                          <Title level={4} style={{ textAlign: 'left', fontSize: 22, color: '#3D3D3D', fontWeight: 'bold', fontFamily: 'PT Serif' }}>
                            {card?.IdIdiomaTraducir[0]?.NombreProducto}
                          </Title>
                          <Text style={{ fontSize: 14, color: '#515151', fontFamily: 'Degular' }}>{card?.IdIdiomaTraducir[0]?.DescripcionProducto}</Text>
                        </Col>
                      </Flex>
                      <br />
                      <br />
                        <Col style={{ position: 'absolute', bottom: 20, textAlign: 'center', width: '100%', padding: '0 24px', marginTop: 10, marginLeft: -35 }}>
                          <Button className='btn-producto-persona'  onClick={() => handleButton(card.IdProducto)} style={{ width: '100%', margin: '0 10px', height: 36 }}>
                            Ver más detalle
                          </Button>
                      </Col>
                    </Card>
                  </Col>
                ))}
              </Row>
              )}
          </Flex>
        </Col>
      </Row>
  )
}

export default ProductosEmpresa