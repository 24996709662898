export const getSubtituloPlan = (nombrePlan) => {
    switch (nombrePlan?.toLowerCase()?.trim()) {
        case 'básico':
            return 'Comienza probando'
        case 'starter':
            return 'Para comenzar'
        case 'developer':
            return 'Para desarrolladores'
        case 'advance':
        case 'advanced':
            return 'Por si necesitas más'
        default:
            return 'Para comenzar'
    }
}

export const onRedirectToProducto = (idProducto, esPruebaGratis) => {
    window.location.replace(`${process.env.REACT_APP_URL_LOGIN_PRIVADO}/loginKeyCloack?idProducto=${idProducto}&esPruebaGratis=${esPruebaGratis}`)
}
