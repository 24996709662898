export const TipoDocumentoId = {
	CONTRATO_PRODUCTO: 1,
	FOLLETO_PRODUCTO: 2,
	DOCUMENTACION: 3,
}

export const TipoProductoId = {
	API: 1,
	REPORTE: 2,
	PACK_REPORTE: 3,
	PACK_API: 4,
	INNOVATION_LAB: 5,


}