import React, { useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom';
import { detalleProductosApi } from '../../services/producto';
import { LoadingOutlined } from '@ant-design/icons';
import { Col, Row, Typography } from 'antd';
import { TipoProductoId } from '../../enum/enum';
import ProductoInnovationLabEmpresa from '../lista-producto-empresa/ProductoInnovationLabEmpresa';
import ProductoApiEmpresa from '../lista-producto-empresa/ProductoApiEmpresa';
import ProductoDashboardEmpresa from '../lista-producto-empresa/ProductoDashboardEmpresa';


const { Text } = Typography;
const ContentCardDetalleProdPersona = () => {
    const navigate = useNavigate();
    const { state } = useLocation();
    const [detalleProductos, setDetalleProductos] = useState({})
    const [loading, setLoading] = useState(false)

    useEffect(() => {
        console.log('Entro aca 1 ')
        const fetchData = async () => {
            console.log('Entro aca 2 ')
            try {
                setLoading(true)
                console.log('Entro aca 3 ')
                const idProducto = state ? state.productId : null;

                if (idProducto) {
                    console.log('Entro aca 4 ')
                    console.log('idProducto: ', idProducto)
                    const informacionDetalleProducto = await detalleProductosApi(idProducto, 1);
                    console.log('informacionDetalleProducto: ', informacionDetalleProducto)
                    setDetalleProductos(informacionDetalleProducto)

                }
            } catch (error) {
                console.log(error)
                setLoading(false)
            } finally {
                setLoading(false)
            }
        }
        fetchData();

    }, [state]);

    console.log('Detalle producto: ', detalleProductos)

    const handleButton = (idProducto) => {
        navigate(`/detalle-producto/${idProducto}`, { state: { productId: idProducto } })
    };

    return (
        <div className='card-details-product'>
            <div >
                {loading ? (
                    <LoadingOutlined size='large' style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: '100%', width: '100%' }} />
                ) :
                    (<>
                        <Row gutter={[32, 0]} className='margin-type-plans'>
                            <Col span={24} className='tipos'>
                                <Text className='text-types-plans'>Tipos de planes disponibles:</Text>
                            </Col>
                        </Row>
                        <Row gutter={{ xs: 0, sm: 0, md: 0, lg: 40, xl: 40, xxl: 40 }}>
                            {detalleProductos?.idTipoProducto === TipoProductoId.REPORTE || detalleProductos?.idTipoProducto === TipoProductoId.PACK_REPORTE ?
                                <>
                                    {detalleProductos?.planesProducto.map((plan, index) => (
                                        <Col key={`prd-${index}`} xs={24} sm={24} md={8}>
                                            <ProductoDashboardEmpresa plan={plan} index={index}></ProductoDashboardEmpresa>
                                        </Col>
                                    ))}
                                </>
                                : detalleProductos?.idTipoProducto === TipoProductoId.PACK_API ?
                                    <>
                                        {detalleProductos.planesProducto.map((plan, index) => (
                                            <Col key={`prd-${index}`} xs={24} sm={24} md={8}>
                                                <ProductoApiEmpresa plan={plan}></ProductoApiEmpresa>
                                            </Col>

                                        ))}

                                    </>
                                    : detalleProductos?.idTipoProducto === TipoProductoId.INNOVATION_LAB ?
                                        <>
                                            {detalleProductos.planesProducto.map((plan, index) => (
                                                <Col key={`prd-${index}`} xs={24} sm={24} md={8}>
                                                    <ProductoInnovationLabEmpresa plan={plan}></ProductoInnovationLabEmpresa>
                                                </Col>
                                            ))}

                                        </>
                                        : null}
                        </Row>
                    </>
                    )}
            </div>
        </div>
    )
}

export default ContentCardDetalleProdPersona