import React, { useState } from 'react';
import { Col, Row, Divider, Typography, Space, Menu, Button, Flex } from 'antd';
import { Link } from 'react-router-dom';

import { InstagramOutlined } from '@ant-design/icons';
import ModalTerminosCondiciones from '../modals/ModalTerminosCondiciones';
import { BiLogoFacebook, BiLogoLinkedin } from 'react-icons/bi';
import { useMenu } from './MenuContext';

import face from '../../assets/images/face.svg'
import linke from '../../assets/images/linkedin.svg'
import insta from '../../assets/images/instagram.svg'

import logo from '../../assets/logos/Brand.svg';

const { Text } = Typography;

const FooterProd = () => {

  const [isModalOpen, setIsModalOpen] = useState(false)

  const { selectedPath } = useMenu();

  const matchRoute = (pattern, path) => {
    // Si la ruta comienza con el patrón (para manejar IDs y parámetros)
    return path.startsWith(pattern);
  };

  const isPathActive = (path) => {
    const pathMappings = {
      '/producto-persona': [
        '/producto-persona',
        '/detalle-producto-persona'
      ],
      '/producto-empresa': [
        '/producto-empresa',
        '/detalle-producto-empresa'
      ],
      'productos': [
        '/producto-persona',
        '/producto-empresa',
        '/detalle-producto-persona',
        '/detalle-producto-empresa'
      ],
      '/home': ['/home'],
      '/contacto': ['/contacto']
    };

    return pathMappings[path]?.some(pattern => 
      matchRoute(pattern, selectedPath)
    ) || false;
  };
  const getActiveStyle = (paths, isSubmenu = false) => {
    // Si paths es un string, convertirlo en array
    const pathArray = typeof paths === 'string' ? [paths] : paths;
    
    // Verificar si alguna de las rutas está activa
    const isActive = pathArray.some(path => isPathActive(path));
    
    // Verificar si es un producto o detalle de producto
    const isProductPath = 
      selectedPath.includes('producto-') || 
      selectedPath.includes('detalle-producto-');
  
    return {
      color: (isActive || (isProductPath && pathArray.includes('productos'))) ? '#FF411C' : '#FFFBFA',
      fontWeight: isSubmenu && isActive ? 700 : 400, // Cambiar fontWeight solo si es un submenú activo
      paddingBottom: 5,
      transition: 'all 0.3s ease'
    };
  };

  const showModal = () => {
    setIsModalOpen(true)
  }
  const handleOk = () => {
      setIsModalOpen(false);
  };

  const handleCancel = () => {
      setIsModalOpen(false);
  };
  
  return (
    <div className='footer-producto'>
      <div className='footer01'>
        <img src={logo} alt='imagen-bolsa' className='logo-footer' />
        <Col span={24}>
          <Divider style={{
            backgroundColor: '#FFFBFA',
            height: 1,
            marginTop: 12,
            marginBottom: 14
          }}></Divider>
        </Col>
        <Row gutter={[16, 16]}>
          <Col xs={0} sm={0} md={24}>
            <Menu theme='dark' mode='horizontal' style={{ lineHeight: '64px', justifyContent: 'flex-start', textAlign: 'center', marginLeft: 4 }} className='menu-footer'>
              <Space size={60}>
                <Link to="/home">
                  <span style={{...getActiveStyle('/home'), fontWeight: 700 }}>Inicio</span>
                </Link>
                <span style={{ ...getActiveStyle(['productos', '/producto-persona', '/producto-empresa']), fontWeight: 700 }}>Productos</span>
                <Link to="/contacto">
                  <span style={{ ...getActiveStyle('/contacto'), fontWeight: 700 }}>Contacto</span>
                </Link>
              </Space>
            </Menu>
          </Col>
        </Row>
        <Row gutter={[16, 16]}>
          <Col xs={0} sm={0} md={24}>
            <div className='submenu-list' style={{ marginTop: -15 }}>
              <Link 
                onClick={() => window.scrollTo(0, 0)} 
                to='/producto-persona' 
                style={{ 
                  ...getActiveStyle('/producto-empresa', true),
                  color: selectedPath === '/producto-persona' ? '#FF411C' : '#FFFBFA',
                  fontFamily: 'Degular'
                  
                }}
              >
                Persona
              </Link>
            </div>
          </Col>
          <Col xs={0} sm={0} md={24}>
            <div className='submenu-list' style={{ position: 'relative', top: -12,  }}>
              <Link 
                onClick={() => window.scrollTo(0, 0)} 
                to='/producto-empresa' 
                style={{ 
                  ...getActiveStyle('/producto-empresa', true),
                  color: selectedPath === '/producto-empresa' ? '#FF411C' : '#FFFBFA',
                  fontFamily: 'Degular',
                }}
              >
                Empresa
              </Link>
            </div>
          </Col>
        </Row>
        {/*Version mobile*/}
        <Row gutter={0}>
        <Col xs={24} sm={24} md={0}>
          <Menu 
            theme='dark' 
            mode='vertical'
            className='menu-footer'
            selectedKeys={[
              isPathActive('/home') ? 'home' : '',
              isPathActive('/producto-persona') ? 'persona' : '',
              isPathActive('/producto-empresa') ? 'empresa' : '',
              isPathActive('/detalle-producto-persona') ? 'persona' : '',
              isPathActive('/detalle-producto-empresa') ? 'empresa' : '',
              isPathActive('/contacto') ? 'contacto' : ''
            ]}
          >
            <Menu.Item key="home">
              <Link to="/home">
                <span style={{ ...getActiveStyle('/home'), fontWeight: 700 }}>Inicio</span>
              </Link>
            </Menu.Item>
            
            <Menu.Item key="productos" style={{ ...getActiveStyle('productos'), fontWeight: 700 }}>
              Productos
            </Menu.Item>
            
            <Menu.Item key="persona" className="submenu-item">
              <Link to='/producto-persona'>
                <span style={{
                  ...getActiveStyle(['/producto-persona', '/detalle-producto-persona', true]),
                  display: 'block',
                  fontSize: 14
                }}>
                  Persona
                </span>
              </Link>
            </Menu.Item>
            
            <Menu.Item key="empresa" className="submenu-item line-menu">
              <Link to='/producto-empresa'>
                <span style={{
                  ...getActiveStyle('/producto-empresa', '/detalle-producto-persona', true),
                  display: 'block',
                  position: 'relative',
                  top: -13,
                  fontSize: 14
                }}>
                  Empresa
                </span>
              </Link>
            </Menu.Item>
            
            <Menu.Item key="contacto" style={{ top: -10 }}>
              <Link to="/contacto">
                <span style={{ ...getActiveStyle('/contacto'), fontWeight: 700 }}>Contacto</span>
              </Link>
            </Menu.Item>
          </Menu>
        </Col>
      </Row>
        
      <Row gutter={[24, 24]} align="middle">
        {/* Texto de Copyright  desktop*/}
        <Col xs={0} sm={0} md={18} style={{ paddingTop: 30 }}>
          <Flex justify="flex-start">
            <Text className='term-text'>
              © Copyright - 2024. Todos los derechos reservados.{' '}
              <Button type="link" className="term" onClick={showModal}>
                Ver Términos y Condiciones
              </Button>
            </Text>
          </Flex>
        </Col>

        <Col xs={24} sm={24} md={0}>
          <Flex justify="flex-start">
            <Text className='term-text'>
              © Copyright - 2024. Todos los derechos reservados.{' '}
              
            </Text>
            
          </Flex>
          <Button type="link" className="term-link" onClick={showModal}>
                Ver Términos y Condiciones
          </Button>
        </Col>

        {/* Iconos de redes sociales */}
        <Col xs={0} sm={0} md={6}>
          <Flex justify="flex-end">
            <Space>
              <Button
                size={34}
                shape="circle"
                href="https://www.facebook.com/bolsadesantiago/"
                target="_blank"
                rel="noopener noreferrer"
                style={{ backgroundColor: '#F7F7F7' }}
              >
                <img src={face} alt="logo_face" />
              </Button>
              <Button
                size={34}
                shape="circle"
                href="https://www.linkedin.com/company/35683183/"
                target="_blank"
                rel="noopener noreferrer"
                style={{ backgroundColor: '#F7F7F7' }}
              >
                <img src={linke} alt="logo_face" />
              </Button>
              <Button
                size={34}
                shape="circle"
                href="https://www.instagram.com/labolsadesantiago/"
                target="_blank"
                rel="noopener noreferrer"
                style={{ backgroundColor: '#F7F7F7' }}
              >
                <img src={insta} alt="logo_face" />
              </Button>
            </Space>
          </Flex>
        </Col>

        {/* Iconos para vista móvil */}
        <Col xs={24} sm={24} md={0}>
          <Flex justify="flex-start">
            <Space>
              <Button
                size={34}
                shape="circle"
                href="https://www.facebook.com/bolsadesantiago/"
                target="_blank"
                rel="noopener noreferrer"
                style={{ backgroundColor: '#F7F7F7' }}
              >
                <img src={face} alt="logo_face" />
              </Button>
              <Button
                size={34}
                shape="circle"
                href="https://www.linkedin.com/company/35683183/"
                target="_blank"
                rel="noopener noreferrer"
                style={{ backgroundColor: '#F7F7F7' }}
              >
                <img src={linke} alt="logo_face" />
              </Button>
              <Button
                size={34}
                shape="circle"
                href="https://www.instagram.com/labolsadesantiago/"
                target="_blank"
                rel="noopener noreferrer"
                style={{ backgroundColor: '#F7F7F7' }}
              >
                <img src={insta} alt="logo_face" />
              </Button>
            </Space>
          </Flex>
        </Col>
      </Row>

      </div>
      <ModalTerminosCondiciones isModalOpen={isModalOpen} handleOk={handleOk} handleCancel={handleCancel} />
    </div>
  );
};

export default FooterProd;
