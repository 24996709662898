import React from 'react'
import ContentCardDetalleProductoEmpresa from './ContentCardDetalleProductoEmpresa'
import ContenidoDetalleProductoEmpresa from './ContenidoDetalleProductoEmpresa'
import { Row } from 'antd'

const Index = () => {
  return (
    <Row className='margin-window'>
        <ContenidoDetalleProductoEmpresa/>
        <ContentCardDetalleProductoEmpresa/>
    </Row>
  )
}

export default Index