import { DownOutlined, UpOutlined } from '@ant-design/icons';
import { Button, Card, Col, Flex, Row, Typography } from 'antd'
import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import { getGuiaInicioPortal } from '../../services/producto';
import { ArrowUp, CaretDown, CaretUp, ChevronDown, ChevronUp } from 'react-iconly';


const { Text } = Typography

const PreguntasFrecuentesProductoPersona = () => {

  const [isDownloadDocumentacion, setIsDownloadDocumentacion] = useState(false)

  const [expanded, setExpanded] = useState({
    1: false,
    2: false,
    3: false,
    4: false,
    5: false,
    6: false,
    7: false,
});

const toggleExpanded = (index) => {
    setExpanded((prevExpanded) => ({
      ...prevExpanded,
      [index]: !prevExpanded[index],
    }));
};

const onDownloadGuiaInicioPersona = async (idTipoCliente) => {
  setIsDownloadDocumentacion(true)
  try {
    const docRes = await getGuiaInicioPortal(idTipoCliente)

    if (docRes) {
      const blob = new Blob([docRes.buffer], { type: 'application/pdf' });
      const linkRedirect = URL.createObjectURL(blob);

      window.open(linkRedirect, "_blank");
    } else {
      console.log("Respuesta nula")
    }
  } catch (error) {
    console.error("Error al descargar documento")
    console.error(error)
  } finally {
    setIsDownloadDocumentacion(false)
  }
}

  return (
    <Row className='row-prod'>
        <Row gutter={[0]} justify="center" align="middle" style={{ textAlign: 'center', paddingBottom: 24 }}>
            <Col xs={0} sm={0} md={24} lg={24} xl={24} xxl={24}>
                <Text className="titulo-contrato"> 
                Preguntas frecuentes
                </Text>
            </Col>
            <Col xs={0} sm={0} md={24} lg={24} xl={24} xxl={24} style={{ marginTop: 12 }}>
               
                <Text className='texto-preg'>
                A continuación, te entregamos una selección de preguntas que te pueden ayudar con la navegación del sitio y la
                contratación de productos.
                </Text>
            </Col>
            <Col xs={0} sm={0} md={24} lg={24} xl={24} xxl={24}>
               
                <Text className='texto-preg'>
                Si tienes alguna otra consulta que no se encuentra en esta sección, por favor comunícate con nosotros a través de{' '}
                <Link
                    className="link-contacto"
                    onClick={() => window.scrollTo(0, 0)}
                    to="/contacto"
                    style={{  }}
                >
                    Contacto
                </Link>
                .
                </Text>
            </Col>
        </Row>

        {/* Mobile */}
        <Row gutter={[0]} justify="start" align="middle" style={{  paddingBottom: 24 }}>
            <Col xs={24} sm={24} md={0} lg={0} xl={0} xxl={0}  style={{ textAlign: 'center' }}>
                <Text className="titulo-contrato"> 
                Preguntas frecuentes
                </Text>
            </Col>
            <Col xs={24} sm={24} md={0} lg={0} xl={0} xxl={0} style={{ marginTop: 12, textAlign: 'left' }} >
               
                <Text className='texto-preg'>
                A continuación, te entregamos una selección de preguntas que te pueden ayudar con la navegación del sitio y la
                contratación de productos.
                </Text>
            </Col>
            <Col xs={24} sm={24} md={0} lg={0} xl={0} xxl={0}  style={{ textAlign: 'left' }}>
               
                <Text className='texto-preg'>
                Si tienes alguna otra consulta que no se encuentra en esta sección, por favor comunícate con nosotros a través de{' '}
                <Link
                    className="link-contacto"
                    onClick={() => window.scrollTo(0, 0)}
                    to="/contacto"
                    style={{  }}
                >
                    Contacto
                </Link>
                .
                </Text>
            </Col>
        </Row>
        <Row gutter={0} >
            <Col md={24} lg={24} style={{ width: '100vw' }}>
                <Card
                className="card-preguntas-prod"
                onClick={() => toggleExpanded(1)}
                >
                {/* Fila para el título y el ícono */}
                <Row gutter={[20, 20]} align="middle">
                    <Col xs={21} sm={22} md={23}>
                    <Text 
                        style={{
                        color: '#3D3D3D',
                        fontWeight: 'bold',
                        fontSize: 20,
                        fontFamily: 'Degular',
                        }}
                    >
                        ¿Qué es Portal Marketplace?
                    </Text>
                    </Col>
                    <Col xs={3} sm={2} md={1} className="icon-col">
                    {expanded[1] ? (
                        <ChevronUp style={{ color: '#FF411C' }} />
                    ) : (
                        <ChevronDown style={{ color: '#FF411C' }} />
                    )}
                    </Col>
                </Row>

                {/* Fila para el contenido del texto */}
                <Row
                    gutter={[20, 20]}
                    style={{
                    
                    }}
                >
                    {/* Texto */}
                    <Col xs={21} sm={22} md={23}>
                    {expanded[1] && (
                        <>
                        <Text className='typography-text' >
                            {`Es un portal web donde usuarios pueden revisar la oferta de productos digitales que tiene la Bolsa de Santiago a disposición del mercado bursátil y financiero.`}
                        </Text>
                        <Text
                            style={{ display: 'block' }}
                            className='typography-text'
                        >
                            {` Dentro de una gran variedad de funcionalidades que tiene este portal, se pueden contratar productos de manera rápida y sencilla, como también autogestionar suscripciones y darse de baja cuando lo deseen, todo en un mismo sitio.`}
                        </Text>
                        <Text
                            style={{ display: 'block' }}
                            className='typography-text'
                        >
                            {` Por el momento los productos que se ofrecen son limitados, en la sección Productos puedes revisar cuáles son. Estamos trabajando para poder continuar incorporando más productos en el futuro de manera que gran parte de los servicios que tiene la Bolsa y nuam sean contratados y gestionados a través de este portal.`}
                        </Text>
                        </>
                    )}
                    </Col>
                    {/* Espacio vacío */}
                    <Col xs={3} sm={22} md={1}></Col>
                </Row>
                </Card>

            </Col>
        </Row>
        <Row style={{ marginTop: 10 }}>
            <Col md={24} lg={24} style={{ width: '100vw' }}>
                <Card
                className="card-preguntas-prod"
                onClick={() => toggleExpanded(2)}
                >
                {/* Fila para el título y el ícono */}
                <Row gutter={[20, 20]} align="middle">
                    <Col xs={21} sm={22} md={23}>
                    <Text
                        style={{
                        color: '#3D3D3D',
                        fontWeight: 'bold',
                        fontSize: 20,
                        fontFamily: 'Degular',
                        }}
                    >
                        ¿Quién puede contratar productos?
                    </Text>
                    </Col>
                    <Col xs={3} sm={2} md={1} className="icon-col">
                    {expanded[2] ? (
                        <ChevronUp style={{ color: '#FF411C' }} />
                    ) : (
                        <ChevronDown style={{ color: '#FF411C' }} />
                    )}
                    </Col>
                </Row>

                {/* Fila para el contenido del texto */}
                <Row
                    gutter={[20, 20]}
                    style={{
                    
                    }}
                >
                    {/* Texto */}
                    <Col xs={21} sm={22} md={23}>
                    {expanded[2] && (
                        <>
                        <Text className='typography-text'>
                            {`Actualmente, Marketplace está dirigido a usuarios de Chile. Solo pueden crear una cuenta y contratar productos personas naturales con cédula chilena vigente y empresas que tengan RUT vigente.`}
                        </Text>
                        </>
                    )}
                    </Col>
                    {/* Espacio vacío */}
                    <Col xs={3} sm={2} md={1} style={{ textAlign: 'center' }}></Col>
                </Row>
                </Card>

            </Col>
        </Row>

        <Row style={{ marginTop: 10 }}>
            <Col md={24} lg={24} style={{ width: '100vw' }}>
                <Card
                className="card-preguntas-prod"
                onClick={() => toggleExpanded(3)}
                >
                {/* Fila para el título y el ícono */}
                <Row gutter={[20, 20]} align="middle">
                    <Col xs={21} sm={22} md={23}>
                    <Text
                        style={{
                        color: '#3D3D3D',
                        fontWeight: 'bold',
                        fontSize: 20,
                        fontFamily: 'Degular',
                        }}
                    >
                        ¿Qué necesito para poder crear una cuenta Persona?
                    </Text>
                    </Col>
                    <Col xs={3} sm={2} md={1} className="icon-col">
                    {expanded[3] ? (
                        <ChevronUp style={{ color: '#FF411C' }} />
                    ) : (
                        <ChevronDown style={{ color: '#FF411C' }} />
                    )}
                    </Col>
                </Row>

                {/* Fila para el contenido del texto */}
                <Row
                    gutter={[20, 20]}
                    style={{
                    
                    }}
                >
                    {/* Texto */}
                    <Col xs={21} sm={22} md={23}>
                    {expanded[3] && (
                        <>
                        <Text className='typography-text'>
                        {`Para poder crear una cuenta como persona natural solo necesitas completar los datos solicitados en el formulario de registro del portal.`}
                        </Text>
                        </>
                    )}
                    </Col>
                    {/* Espacio vacío */}
                    <Col xs={3} sm={2} md={1} style={{ textAlign: 'center' }}></Col>
                </Row>
                </Card>

            </Col>
        </Row>

        <Row style={{ marginTop: 10 }}>
            <Col md={24} lg={24} style={{ width: '100vw' }}>
                <Card
                className="card-preguntas-prod"
                onClick={() => toggleExpanded(4)}
                >
                {/* Fila para el título y el ícono */}
                <Row gutter={[20, 20]} align="middle">
                    <Col xs={21} sm={22} md={23}>
                    <Text
                        style={{
                        color: '#3D3D3D',
                        fontWeight: 'bold',
                        fontSize: 20,
                        fontFamily: 'Degular',
                        }}
                    >
                        ¿Cómo puedo contratar productos?
                    </Text>
                    </Col>
                    <Col xs={3} sm={2} md={1} className="icon-col">
                    {expanded[4] ? (
                        <ChevronUp style={{ color: '#FF411C' }} />
                    ) : (
                        <ChevronDown style={{ color: '#FF411C' }} />
                    )}
                    </Col>
                </Row>

                {/* Fila para el contenido del texto */}
                <Row
                    gutter={[20, 20]}
                    style={{
                    
                    }}
                >
                    {/* Texto */}
                    <Col xs={21} sm={22} md={23}>
                    {expanded[4] && (
                        <>
                        <Text className='typography-text'>
                        {`Primero debes haber creado una cuenta, una vez la hayas activado podrás ingresar con tus credenciales al Portal Persona y desde ahí en la sección Catálogo de productos contratar aquellos que más te interesen.`}
                        </Text>
                        <Text
                        style={{ display: 'block' }}
                            className='typography-text'
                        >
                           {`A continuación, te dejamos una `} <Button type='link' style={{ color: '#0E9753', fontWeight: 'bold', cursor: 'pointer', marginLeft: -15, marginRight: -15 }} onClick={() => onDownloadGuiaInicioPersona(1)}>Guía de inicio Persona </Button> {`que te puede ayudar con el proceso de creación de cuenta y contratación de productos.`}
                        </Text>
                        </>
                    )}
                    </Col>
                    {/* Espacio vacío */}
                    <Col xs={3} sm={2} md={1} style={{ textAlign: 'center' }}></Col>
                </Row>
                </Card>

            </Col>
        </Row>

        <Row style={{ marginTop: 10 }}>
            <Col md={24} lg={24} style={{ width: '100vw' }}>
                <Card
                className="card-preguntas-prod"
                onClick={() => toggleExpanded(5)}
                >
                {/* Fila para el título y el ícono */}
                <Row gutter={[20, 20]} align="middle">
                    <Col xs={21} sm={22} md={23}>
                    <Text
                        style={{
                        color: '#3D3D3D',
                        fontWeight: 'bold',
                        fontSize: 20,
                        fontFamily: 'Degular',
                        }}
                    >
                        ¿Cómo es la firma de contratos?
                    </Text>
                    </Col>
                    <Col xs={3} sm={2} md={1} className="icon-col">
                    {expanded[5] ? (
                        <ChevronUp style={{ color: '#FF411C' }} />
                    ) : (
                        <ChevronDown style={{ color: '#FF411C' }} />
                    )}
                    </Col>
                </Row>

                {/* Fila para el contenido del texto */}
                <Row
                    gutter={[20, 20]}
                    style={{
                    
                    }}
                >
                    {/* Texto */}
                    <Col xs={21} sm={22} md={23}>
                    {expanded[5] && (
                        <>
                        <Text className='typography-text'>
                        {`En Portal Marketplace al momento de contratar productos debes leer y aceptar los Términos y Condiciones asociados al servicio que se desea comprar para poder continuar con la contratación y habilitación del producto.`}
                        </Text>
                        <Text
                        style={{ display: 'block' }}
                            className='typography-text'
                        >
                            {`En la sección Productos de este sitio, si te diriges al detalle de cada producto podrás ver de manera previa el contrato (Términos y Condiciones) asociado a cada servicio.`}
                        </Text>
                        <Text
                        style={{ display: 'block' }}
                            className='typography-text'
                        >
                           {`Te sugerimos que si eres una Empresa descargues el documento con anterioridad y lo valides con las áreas legales correspondientes antes de continuar con la contratación.`}
                        </Text>
                        </>
                    )}
                    </Col>
                    {/* Espacio vacío */}
                    <Col xs={3} sm={2} md={1} style={{ textAlign: 'center' }}></Col>
                </Row>
                </Card>

            </Col>
        </Row>
        
        <Row style={{ marginTop: 10 }}>
            <Col md={24} lg={24} style={{ width: '100vw' }}>
                <Card
                className="card-preguntas-prod"
                onClick={() => toggleExpanded(6)}
                >
                {/* Fila para el título y el ícono */}
                <Row gutter={[20, 20]} align="middle">
                    <Col xs={21} sm={22} md={23}>
                    <Text
                        style={{
                        color: '#3D3D3D',
                        fontWeight: 'bold',
                        fontSize: 20,
                        fontFamily: 'Degular',
                        }}
                    >
                        ¿Qué medios de pago se aceptan?
                    </Text>
                    </Col>
                    <Col xs={3} sm={2} md={1} className="icon-col">
                    {expanded[6] ? (
                        <ChevronUp style={{ color: '#FF411C' }} />
                    ) : (
                        <ChevronDown style={{ color: '#FF411C' }} />
                    )}
                    </Col>
                </Row>

                {/* Fila para el contenido del texto */}
                <Row
                    gutter={[20, 20]}
                    style={{
                    
                    }}
                >
                    {/* Texto */}
                    <Col xs={21} sm={22} md={23}>
                    {expanded[6] && (
                        <>
                        <Text className='typography-text'>
                        {`Los medios de pago permitidos para la contratación de productos son tarjetas de crédito, tarjetas de débito y transferencias electrónicas.`}
                        </Text>
                        <Text
                        style={{ display: 'block' }}
                            className='typography-text'
                        >
                           {`Los pagos deben ser realizados de manera manual mensual o anualmente (según corresponda) para poder mantener activa las suscripciones. No realizaremos cobros automáticos a tus tarjetas o cuenta bancaria.`}
                        </Text>
                        </>
                    )}
                    </Col>
                    {/* Espacio vacío */}
                    <Col xs={3} sm={2} md={1} style={{ textAlign: 'center' }}></Col>
                </Row>
                </Card>

            </Col>
        </Row>
        

        <Row style={{ marginTop: 10 }}>
            <Col md={24} lg={24} style={{ width: '100vw' }}>
                <Card
                className="card-preguntas-prod"
                onClick={() => toggleExpanded(7)}
                >
                {/* Fila para el título y el ícono */}
                <Row gutter={[20, 20]} align="middle">
                    <Col xs={21} sm={22} md={23}>
                    <Text
                        style={{
                        color: '#3D3D3D',
                        fontWeight: 'bold',
                        fontSize: 20,
                        fontFamily: 'Degular',
                        }}
                    >
                       ¿Puedo cancelar mis suscripciones en cualquier momento?
                    </Text>
                    </Col>
                    <Col xs={3} sm={2} md={1} className="icon-col">
                    {expanded[7] ? (
                        <ChevronUp style={{ color: '#FF411C' }} />
                    ) : (
                        <ChevronDown style={{ color: '#FF411C' }} />
                    )}
                    </Col>
                </Row>

                {/* Fila para el contenido del texto */}
                <Row
                    gutter={[20, 20]}
                    style={{
                    
                    }}
                >
                    {/* Texto */}
                    <Col xs={21} sm={22} md={23}>
                    {expanded[7] && (
                        <>
                        <Text className='typography-text'>
                        {`Para aquellos productos de renovación automática, puedes cancelar cuando quieras tus suscripciones en Marketplace, podrás seguir utilizando el producto hasta que se cumpla el último periodo de pago o de prueba.`}
                        </Text>
                        <Text
                        style={{ display: 'block' }}
                            className='typography-text'
                        >
                            {`Para el resto de productos que no son de renovación automática no es necesario cancelar la suscripción, ya que automáticamente se da de baja cuando se cumple el último periodo de pago o de prueba.`}
                        </Text>
                        </>
                    )}
                    </Col>
                    {/* Espacio vacío */}
                    <Col xs={3} sm={2} md={1} style={{ textAlign: 'center' }}></Col>
                </Row>
                </Card>

            </Col>
        </Row>
    </Row>
  )
}

export default PreguntasFrecuentesProductoPersona