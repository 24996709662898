import { Avatar, Button, Card, Col, Flex, Row, Tooltip, Typography, Space } from 'antd'
import React, { useState } from 'react'

import logo from '../../assets/images/Brand.svg'
import chile from '../../assets/images/chile.svg'
import colombia from '../../assets/images/colombia.svg'
import peru from '../../assets/images/peru.svg'
import ModalRegistro from '../modals/ModalRegistro'

const { Title, Text } = Typography;
const SeleccionarPais = () => {

  const [isModalOpen, setIsModalOpen] = useState(false);

  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleOk = () => {
    setIsModalOpen(false);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  return (
    <div className='margin-pais'>
      <Row gutter={{ xs: 0, md: 0, lg: 48 }}>
        {/* texto registro */}
        <Col xs={24} lg={12} className='container-card-pais'>
          <Card className='card-pais'>
            <Row>
              <Col span={24} style={{ marginBottom: 20 }}>
                <Text className='title-register' >¡Regístrate ahora!</Text>
              </Col>
            </Row>
            <Row>
              <Col md={20} lg={20} xl={20} xxl={20} style={{ marginBottom: 20 }}>
                <Text className='subtitle-register'>Selecciona tu país</Text>
              </Col>
            </Row>
            <Row>
              <Col span={22} style={{ marginBottom: 20 }}>
                <Text className='text-register'>Recuerda que puedes iniciar el proceso si eres ciudadano o tu empresa está registrada en el país seleccionado.</Text>
              </Col>
            </Row>
            <Flex justify='flex-end' style={{ marginTop: 10 }}>
              <img src={logo} alt='img-nuam' className='logo-nuam'/>
            </Flex>
          </Card>
        </Col>

        {/* seleccion paies */}
        <Col xs={24} lg={12} style={{ display: 'grid' }}>
          <Card className="card-pais-selector" onClick={showModal} style={{
            border: `2px solid #FF411C`, cursor: 'pointer'
          }}>
            <Row gutter={0}>
              <Col xs={24} sm={24} md={14}>
                <Space>
                  <Avatar src={chile} className='img-pais' />
                  <Text className='pais-text'>Chile</Text>
                </Space>
              </Col>
              <Col xs={24} sm={24} md={10} className='top-button-c' style={{ textAlign: 'right', paddingTop: 3 }}>
                <Button
                 
                  className='btn-onboarding-pais-seleccion'
                  onClick={showModal}
                >
                  Iniciar
                </Button>
              </Col>
            </Row>
          </Card>
          <Tooltip overlayClassName='tooltip-pais' lacement="top" title={'¡Disponible muy pronto!'} arrow={true} style={{ fontFamily: 'Degular' }}>
            <Card className="card-pais-selector" style={{
              border: `2px solid #CCCCCC`, cursor: 'pointer'
            }}>
              <Row gutter={0}>
                <Col xs={24} sm={24} md={14}>
                  <Space>
                    <Avatar src={colombia} className='img-pais' />
                    <Text className='pais-text'>Colombia</Text>
                  </Space>
                </Col>
                <Col xs={24} sm={24} md={10} className='top-button-c' style={{ textAlign: 'right', paddingTop: 3  }}>
                  <Button
                   disabled
                    className='btn-onboarding-pais-seleccion-disabled'
                  >
                    Iniciar
                  </Button>
                </Col>
              </Row>
            </Card>
          </Tooltip>
          <Tooltip overlayClassName='tooltip-pais' placement="top" title={'¡Disponible muy pronto!'} arrow={true} style={{ fontFamily: 'Degular' }}>
            <Card className="card-pais-selector last-card" style={{
              border: `2px solid #CCCCCC`, marginBottom: 0, cursor: 'pointer'
            }}>
              <Row gutter={0}>
                <Col xs={24} sm={24} md={14}>
                  <Space>
                    <Avatar src={peru} className='img-pais' />
                    <Text className='pais-text'>Perú</Text>
                  </Space>
                </Col>
                <Col xs={24} sm={24} md={10} className='top-button-c' style={{ textAlign: 'right', paddingTop: 3  }}>
                  <Button
                    disabled
                    className='btn-onboarding-pais-seleccion-disabled '
                  >
                    Iniciar
                  </Button>
                </Col>
              </Row>
            </Card>
          </Tooltip>
        </Col>
      </Row>
      <ModalRegistro isModalOpen={isModalOpen} handleOk={handleOk} handleCancel={handleCancel} />
    </div >
  )
}

export default SeleccionarPais