import { Col, Row, Typography } from 'antd'
import React from 'react'
import CardPreguntas from './CardPreguntas';


const { Title, Text } = Typography;
const PreguntasFrecuentes = () => {
  return (
    <>
      <Row >
        <Col xs={24} sm={23} md={22}  style={{ paddingTop: 48 }}>
                <Row gutter={12}>
                    <Col span={24} style={{ paddingBottom: 12 }}>
                    <Text className='title-question' style={{ color: '#F6FFFD', marginBottom: '16px' }}>
                      Preguntas frecuentes
                    </Text>
                    </Col>
                </Row>
                <Row>
                    <Col span={24}  style={{ paddingBottom: 24 }}>
                    <Text className='text-question' style={{ color: '#F6FFFD', display: 'block' }}>
                      A continuación, te entregamos una selección de las preguntas que recibimos con mayor frecuencia y sus respuestas.
                    </Text>
                    </Col>
                </Row>
            </Col>
      </Row>
      <Row>
        <Col md={23} lg={22}>
          <CardPreguntas/>
        </Col>
       
      </Row>
    </>
  )
}

export default PreguntasFrecuentes