
import AppRoutes from './routing/Routing';
import './App.css'
import { BrowserRouter } from 'react-router-dom';
import { MenuProvider } from './Components/layout/MenuContext';

import './assets/styles/AntOnboarding.scss';

function App() {

  return (
    <BrowserRouter>
      <MenuProvider>
        <AppRoutes/>
      </MenuProvider>
    </BrowserRouter>
  );
}

export default App;
