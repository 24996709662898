import React from 'react'
import { Card, Row, Col, Space, Button, Flex, Typography } from 'antd'
import { getSubtituloPlan } from '../../services/helpers/stringHelper'
import { SafetyCertificateOutlined } from '@ant-design/icons';
import { onRedirectToProducto } from '../../services/helpers/stringHelper';
import { separadorDeMiles } from '../../utils/Utils';
import LogoPlan from '../detalle-producto-persona/LogoPlan';
import { ShieldDone } from 'react-iconly';


const { Text } = Typography;
const ProductoApiEmpresa = ({ plan }) => {

    return (
        <Card className='card-detail-prod' bordered={false} style={{ background: '#3D3D3D' }}>
            <Row justify="space-between" gutter={0}>
                <Col span={24} style={{ marginBottom: 24 }}>
                    <Space>
                        <LogoPlan nombrePlan={plan?.nombrePlan}></LogoPlan>
                        <Row>
                            <Col span={24}>
                                {plan.nombrePlan === 'Básico' ? <p style={{ fontWeight: 'bold', fontSize: 24, color: '#FFFBFA', color: '#FFFBFA', marginBottom: 0, fontFamily: 'Degular' }}>Básico</p> :
                                    <p style={{ fontWeight: 'bold', textAlign: 'justify', marginBottom: 0, fontSize: 24, color: '#FFFBFA', marginLeft: 8, fontFamily: 'Degular' }}>{plan?.nombrePlan}</p>}
                            </Col>
                            <Col span={24}>
                                <p style={{ fontSize: 12, color: '#FFFBFA', marginTop: 0, marginLeft: 8, fontFamily: 'Degular' }}>{getSubtituloPlan(plan?.nombrePlan)}</p>
                            </Col>
                        </Row>
                        <p style={{ fontWeight: 'bold', fontSize: 28, color: '#FFFBFA', fontFamily: 'PT Serif' }}>UF {plan.precio} /mes</p>
                    </Space>
                </Col>

                {/* version antigua por si se debe actualizar */}
                {/* <Col xs={0} sm={0} md={24} lg={13} xl={13} xxl={13}>
                    <Row>
                        <Col xs={0} sm={0} md={7} lg={6} xl={6} xxl={6}>
                            <LogoPlan nombrePlan={plan?.nombrePlan}></LogoPlan>
                        </Col>
                        <Col xs={0} sm={0} md={17} lg={18} xl={18} xxl={18}>
                            {plan.nombrePlan === 'Básico' ? <p style={{ fontWeight: 'bold', fontSize: 24, color: '#FFFBFA', color: '#FFFBFA', marginBottom: 0, fontFamily: 'Degular' }}>Básico</p> :
                                <p style={{ fontWeight: 'bold', textAlign: 'justify', marginBottom: 0, fontSize: 24, color: '#FFFBFA', marginLeft: 8, fontFamily: 'Degular' }}>{plan?.nombrePlan}</p>}
                            <p style={{ fontSize: 12, color: '#FFFBFA', marginTop: 0, marginLeft: 8, fontFamily: 'Degular' }}>{getSubtituloPlan(plan?.nombrePlan)}</p>
                        </Col>
                    </Row>
                </Col>
                <Col xs={0} sm={0} md={24} lg={11} xl={11} xxl={11} style={{ marginRight: 0, paddingRight: 0 }}>
                    <p style={{ fontWeight: 'bold', fontSize: 28, color: '#FFFBFA', fontFamily: 'PT Serif' }}>UF {plan.precio} /mes</p>
                </Col> */}
            </Row>
            <div style={{ color: '#FFFBFA' }}>
                <p style={{ textAlign: 'justify', fontWeight: 'bold', fontSize: 24, fontFamily: 'Degular', marginTop: 16, marginBottom: 12 }}>Este plan incluye</p>
                {/*<Row gutter={0} style={{ marginTop: 16 }}>
                    <Space>
                        <ShieldDone style={{ color: '#FF411C' }} />
                        <>
                            <Text style={{ fontFamily: 'Degular', fontWeight: 'bold', color: '#FFFBFA', fontSize: 16, marginTop: 16 }}>N° Requests / {plan.nombrePlan === 'Básico' ? 'día' : 'mes'}: </Text>
                            <Text style={{ fontFamily: 'Degular', color: '#FFFBFA', fontSize: 16, marginTop: 16 }}>{separadorDeMiles(plan.cantidadRequest)}</Text>
                        </>
                    </Space>
                </Row>*/}
                <Row gutter={0} style={{ marginTop: 16 }}>
                    <Col span={2}><ShieldDone size={24} style={{ color: '#FF411C', }} /></Col>
                    <Col span={22} className='texto-plan' style={{ fontFamily: 'Degular', fontWeight: 'bold', fontSize: 16, }}><Text style={{ fontFamily: 'Degular', fontWeight: 'bold', color: '#FFFBFA', fontSize: 16, marginTop: 16 }}>N° Requests / {plan.nombrePlan === 'Básico' ? 'día' : 'mes'}: {separadorDeMiles(plan.cantidadRequest)}</Text></Col>
                </Row>
                {plan.mercados === 'C&L' ?
                    (
                    <Row gutter={0} style={{ marginTop: 16 }}>
                        <Col span={2}><ShieldDone size={24} style={{ color: '#FF411C', }} /></Col>
                        <Col span={22} className='texto-plan' style={{ fontFamily: 'Degular', fontWeight: 'bold', fontSize: 16 }}><Text style={{ fontFamily: 'Degular', fontWeight: 'bold', color: '#FFFBFA', fontSize: 16, marginTop: 16 }}><strong>Mercado</strong>: Compensación y Liquidación</Text></Col>
                    </Row>
                    ) : (
                        <Row gutter={0} style={{ marginTop: 16 }}>
                            <Col span={2}><ShieldDone size={24} style={{ color: '#FF411C', }} /></Col>
                            <Col span={22} className='texto-plan' style={{ fontFamily: 'Degular', fontWeight: 'bold', fontSize: 16 }}><Text style={{ fontFamily: 'Degular', fontWeight: 'bold', color: '#FFFBFA', fontSize: 16, marginTop: 16 }}><strong className='estilos-mercado'>Mercado: </strong><Text className='estilos-textos'>{plan.mercados?.replace('Mercado:', '')}</Text></Text></Col>
                        </Row>
                        
                    )}

                {plan.nombrePlan === 'Básico' ?
                    ( <Row gutter={0} style={{ marginTop: 16 }}>
                        <Col span={2}><ShieldDone size={24} style={{ color: '#FF411C', }} /></Col>
                        <Col span={22} className='texto-plan' style={{ fontFamily: 'Degular', fontWeight: 'bold', fontSize: 16,  }}><Text style={{ fontFamily: 'Degular', fontSize: 16, color: '#FFFBFA' }}>Data End of day</Text></Col>
                    </Row>
                   ) :
                    (<Row gutter={0} style={{ marginTop: 16 }}>
                        <Col span={2}><ShieldDone size={24} style={{ color: '#FF411C', }} /></Col>
                        <Col span={22} className='texto-plan' style={{ fontFamily: 'Degular', fontWeight: 'bold', fontSize: 16 }}><Text style={{ fontFamily: 'Degular', fontWeight: 'bold', color: '#FFFBFA', fontSize: 16 }}>Periodo de tiempo: </Text><Text style={{ fontFamily: 'Degular', fontSize: 16, color: '#FFFBFA' }}>15min Delayed</Text></Col>
                    </Row>
                        
                    )}

                {plan.nombrePlan === 'Básico' ?
                    (<Row gutter={0} style={{ marginTop: 16 }}>
                        <Col span={2}><ShieldDone size={24} style={{ color: '#FF411C', }} /></Col>
                        <Col span={22} className='texto-plan' style={{ fontFamily: 'Degular', fontWeight: 'bold', fontSize: 16 }}><Text style={{ fontFamily: 'Degular', fontWeight: 'bold', color: '#FFFBFA', fontSize: 16 }}>10 instrumentos aleatorios</Text></Col>
                    </Row>
                    ) :
                    (<Row gutter={0} style={{ marginTop: 16 }}>
                        <Col span={2}><ShieldDone size={24} style={{ color: '#FF411C', }} /></Col>
                        <Col span={22} className='texto-plan' style={{ fontFamily: 'Degular', fontWeight: 'bold', fontSize: 16 }}><Text style={{ fontFamily: 'Degular', fontWeight: 'bold', color: '#FFFBFA', fontSize: 16 }}>Selección de instrumentos: </Text><Text style={{ fontFamily: 'Degular', fontSize: 16, color: '#FFFBFA' }}>Si</Text></Col>
                    </Row>
                    )}
                    <Row gutter={0} style={{ marginTop: 16 }}>
                        <Col span={2}><ShieldDone size={24} style={{ color: '#FF411C', }} /></Col>
                        <Col span={22} className='texto-plan' style={{ fontFamily: 'Degular', fontWeight: 'bold', fontSize: 16 }}><Text style={{ fontFamily: 'Degular', fontWeight: 'bold', color: '#FFFBFA', fontSize: 16 }}>Info. Historica: </Text><Text style={{ fontFamily: 'Degular', fontSize: 16, color: '#FFFBFA' }}>{plan.infoHistorica} {plan.infoHistorica > 1 ? 'años' : 'año'}</Text></Col>
                    </Row>
                    
            </div>
            <Flex justifyContent='center' style={{ marginTop: 24 }}>
                
                    {plan?.precio === 0 && (
                        <Button
                            onClick={() => onRedirectToProducto(plan.idPlanProducto, true)}
                            className='btn-onboarding-empresa'
                            style={{ width: '100%'}}
                        >
                            <strong>Prueba gratis</strong>
                        </Button>
                    )}
                    {plan?.precio > 0 && (
                        <Button
                            onClick={() => onRedirectToProducto(plan.idPlanProducto, false)}
                            className='btn-onboarding-empresa'
                            style={{ width: '100%' }}
                        >
                            <strong>Contratar plan</strong>
                        </Button>
                    )}
               
            </Flex>
        </Card>
    )
}

export default ProductoApiEmpresa