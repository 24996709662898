import { Col, Row, Typography, Card } from 'antd'
import React from 'react'


const { Title, Text } = Typography;
const BannerProductoPersona = () => {
  return (
    <Row className="row-padd img-producto-persona-mobile">
      <Row gutter={0} className="banner-persona">
        <Col xs={24} sm={24} md={12} lg={12} className='padd-banner' style={{  }}> {/*Preguntar si es 77px o 124px en left*/}
          <Card className="card-prod-pe">
            <Row>
              <Col span={24}>
                <Text className="title-persona">Conoce nuestros</Text>
              </Col>
            </Row>
            <Row>
              <Col xs={15} sm={24} md={24}>
                <Text className="subtitle-persona">Productos enfocados a personas</Text>
              </Col>
            </Row>
            <Row style={{ paddingTop: 20 }}>
              <Col xs={14} sm={15} md={24}>
                <Text className="texto-persona">
                  En esta sección podrás encontrar la oferta de productos que tenemos
                  especialmente para personas naturales, junto a las principales
                  funcionalidades de este portal web.
                </Text>
              </Col>
            </Row>
          </Card>
        </Col>
        <Col xs={0} sm={0} md={2} lg={2}></Col>
        <Col xs={0} sm={0} md={8} lg={8} className="image-prod-persona">
          
        </Col>
      </Row>
    </Row>

  )
}

export default BannerProductoPersona