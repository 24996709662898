import { RocketOutlined, SafetyCertificateOutlined, SettingOutlined, StarOutlined } from '@ant-design/icons';
import { Card, Col, Flex, Row, Typography } from 'antd'
import React from 'react'
import { Setting, ShieldDone, Star } from 'react-iconly'


const { Title, Text } = Typography;
const CaracteristicasProductoEmpresa = () => {
    return (
        <Row className='row-caract'>
            <Row>
                <Flex justify='start'>
                    <Title level={2} style={{ color: '#3D3D3D', fontWeight: 700, fontFamily: 'PT Serif' }}>Algunas características</Title>
                </Flex>

            </Row>
            <Row gutter={[10, 0]} className=''>
                <Col xs={12} sm={12} md={6} lg={6} style={{ marginBottom: 12 }}>
                    <Card className='card-prod' style={{ height: '100%' }} >
                        <Flex align='center'>
                            <RocketOutlined style={{ fontSize: 24, color: '#FF411C' }} />
                            <Text className='title-cards-persona'>Simple</Text>
                        </Flex>
                        <Row gutter={[0, 20]}>
                            <Col span={24} style={{ marginTop: 20 }}>
                                <Text className='text-cards-persona'>Nuestro marketplace es intuitivo y fácil de navegar.</Text>
                            </Col>
                        </Row>
                    </Card>
                </Col>
                <Col xs={12} sm={12} md={6} lg={6} style={{ marginBottom: 12 }}>
                    <Card className='card-prod' style={{ height: '100%' }} >
                        <Flex align='center'>
                            <Star style={{ fontSize: 24, color: '#FF411C' }} />
                            <Text className='title-cards-persona'>Ágil</Text>
                        </Flex>
                        <Row gutter={[0, 20]}>
                            <Col span={24} style={{ marginTop: 20 }}>
                                <Text className='text-cards-persona'>Contrata y gestiona tus productos de manera inmediata, con unos pocos clicks ya estarás registrado y utilizando nuestros productos.</Text>
                            </Col>
                        </Row>
                    </Card>
                </Col>
                <Col xs={12} sm={12} md={6} lg={6} style={{ marginBottom: 12 }}>
                    <Card className='card-prod' style={{ height: '100%' }} >
                        <Flex align='center'>
                            <Setting style={{ fontSize: 24, color: '#FF411C' }} />
                            <Text className='title-cards-persona'>Flexible</Text>
                        </Flex>
                        <Row gutter={[0, 20]}>
                            <Col span={24} style={{ marginTop: 20 }}>
                                <Text className='text-cards-persona'>Ingresa cuando quieras desde cualquier dispositivo. Te entregamos la libertad para que puedas contratar y cancelar cuando quieras. Además podrás administrar los usuarios asociados a tu empresa y sus privilegios cuando lo necesites.</Text>
                            </Col>
                        </Row>
                    </Card>
                </Col>
                <Col xs={12} sm={12} md={6} lg={6} style={{ marginBottom: 12 }}>
                    <Card className='card-prod' style={{ height: '100%' }} >
                        <Flex align='center'>
                            <ShieldDone style={{ fontSize: 24, color: '#FF411C' }} />
                            <Text className='title-cards-persona'>Seguro</Text>
                        </Flex>
                        <Row gutter={[0, 20]}>
                            <Col span={24} style={{ marginTop: 20 }}>
                                <Text className='text-cards-persona'>Te garantizamos que todos tus datos están protegidos, cumplimos con los estándares y pautas de seguridad exigidos por la ley</Text>
                            </Col>
                        </Row>
                    </Card>
                </Col>
            </Row>
        </Row>

    )
}

export default CaracteristicasProductoEmpresa