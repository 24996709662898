import { Button, Col, Divider, Modal, Row, Typography, Image } from 'antd';
import React from 'react';
import closeIcon from '../../assets/images/closeIcon.svg'
import uno from '../../assets/images/❖  Iconly.svg';
import dos from '../../assets/images/❖  Iconly (1).svg';

const URL_CREAR_CUENTA_PERSONA = process.env.REACT_APP_URL_REGISTRO_PERSONA_PRIVADO;

const { Title, Text } = Typography;

const ModalRegistro = ({ isModalOpen, handleOk, handleCancel }) => {
  return (
    <Modal
      className="modal-registro"
      footer={null}
      open={isModalOpen}
      onOk={handleOk}
      onCancel={handleCancel}
      width={700}
      centered
      closeIcon={//se dejo en duro el svg para poder cambiar su color con css
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" className="hover-effect">
          <path d="M14.3955 9.59497L9.60352 14.387" stroke="#FFFBFA" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
          <path d="M14.3971 14.3899L9.60107 9.5929" stroke="#FFFBFA" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
          <path fill-rule="evenodd" clip-rule="evenodd" d="M16.3345 2.75024H7.66549C4.64449 2.75024 2.75049 4.88924 2.75049 7.91624V16.0842C2.75049 19.1112 4.63549 21.2502 7.66549 21.2502H16.3335C19.3645 21.2502 21.2505 19.1112 21.2505 16.0842V7.91624C21.2505 4.88924 19.3645 2.75024 16.3345 2.75024Z" stroke="#FFFBFA" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
        </svg>
      }
    >
      <Row>
        <Col span={24} style={{ height: 48 }}></Col>
      </Row>
      <Row gutter={0}>
        <Col xs={24} sm={24} md={11} style={{ textAlign: 'center' }}>
          <img src={uno} alt="Empresa" className='img-modal-seletor' />
          <p className='text-selector-tipo'>Regístrate como</p>
          <Title level={4} style={{ color: '#FFFBFA', textAlign: 'center', marginTop: 0, fontFamily: 'Degular' }}>empresa</Title>
          <Text style={{ marginBottom: 8, color: '#F4F4F4', fontSize: 16, textAlign: 'left', display: 'block', marginTop: 6, fontFamily: 'Degular', fontWeight: 400 }}>
            Para la creación de una cuenta Empresa te solicitaremos subir el e-RUT de la empresa, el cual se obtiene a través del sitio de Servicio de Impuestos Internos. Sugerimos que el administrador sea una persona con acceso a los medios de pago de la empresa.
          </Text>
          <Button href={`${URL_CREAR_CUENTA_PERSONA}crear-cuenta-empresa`} className="btn-onboarding-m" style={{ borderColor: '#FF411C', color: '#F7F7F7', marginTop: 18, marginBottom: 18 }}>
            Registrar empresa
          </Button>
        </Col>

        <div className='separador'>
          <Divider type="vertical" style={{ backgroundColor: '#CCCCCC', height: '92%', width: 2 }} />
        </div>
        <Col
          xs={24} sm={24} md={10}
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            textAlign: 'center',
          }}
        >
          <img src={dos} alt="Persona" className='img-modal-seletor' />
          <p className='text-selector-tipo'>Regístrate como</p>
          <Title level={4} style={{ color: '#FFFBFA', textAlign: 'center', marginTop: 0, fontFamily: 'Degular' }}>persona</Title>
          <Button href={URL_CREAR_CUENTA_PERSONA} className="btn-onboarding-m" style={{ borderColor: '#FF411C', color: '#F7F7F7', marginTop: 18, marginBottom: 18 }}>
            Registrar persona
          </Button>
        </Col>
      </Row>
    </Modal>
  );
};

export default ModalRegistro;
