import { Card, Col, Row, Typography } from 'antd'
import React from 'react'

import empresa from '../../assets/images/empresa_final.svg';

const { Title, Text } = Typography;
const BannerProductoEmpresa = () => {
  return (
    <Row className="row-padd img-producto-empresa-mobile">
      <Row gutter={0} className="banner-empresa">
        <Col xs={24} sm={24} md={12} lg={12} className='padd-banner' style={{  }}>
          <Card className="card-prod-pe">
            <Row>
              <Col span={24}>
                <Text className="title-persona">Conoce nuestros</Text>
              </Col>
            </Row>
            <Row>
              <Col xs={15} sm={24} md={24}>
                <Text className="subtitle-persona">Productos enfocados a empresas</Text>
              </Col>
            </Row>
            <Row style={{ paddingTop: 20 }}>
              <Col xs={14} sm={15} md={24}>
                <Text className="texto-persona">
                  En esta sección podrás encontrar la oferta de productos que tenemos
                  especialmente para empresas o instituciones, junto a las principales
                  funcionalidades de este portal web.
                </Text>
              </Col>
            </Row>
          </Card>
        </Col>
        <Col xs={0} sm={0} md={2} lg={2}></Col>
        <Col xs={0} sm={0} md={8} lg={7} className="image-prod-empresa">
          
        </Col>
      </Row>
    </Row>
  )
}

export default BannerProductoEmpresa