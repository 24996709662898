import React from 'react'
import HeaderHome from '../Components/layout/Header'
import FooterHome from '../Components/layout/Footer'
import ContentInicio from '../Components/home/ContentInicio'


const HomePage = () => {
  return (
    <>
        <HeaderHome/>
        <ContentInicio/>
        <FooterHome/>
    </>
  )
}

export default HomePage