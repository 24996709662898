import { Button, Col, Divider, Flex, Row, Space, Typography } from 'antd'
import React from 'react'
import { BiLogoFacebook, BiLogoLinkedin } from "react-icons/bi";
import { InstagramOutlined } from '@ant-design/icons';
import face from '../../assets/images/face.svg'
import linke from '../../assets/images/linkedin.svg'
import insta from '../../assets/images/instagram.svg'

const { Text } = Typography;
const FooterHome = () => {

  return (
    <div className='row-footer'>
      <div className='version-dektop'>
        <Flex className='' justify='flex-end' align='center'>
          <Col>
            <Text style={{ color: '#FFFBFA', fontSize: 20, fontFamily: 'Degular', letterSpacing: 0.04 }}>Síguenos en nuestras redes sociales</Text>
          </Col>
          <Col >
            <Divider
              type='vertical'
              style={{
                backgroundColor: '#FF411C',
                height: 1,
                marginLeft: 10,
                marginRight: 10,
                width: 100
              }}
            />
          </Col>
          <Col>
            <Space size={8}>
            <Button
                size={34}
                shape="circle"
                href="https://www.facebook.com/bolsadesantiago/"
                target="_blank"
                rel="noopener noreferrer"
                style={{ backgroundColor: '#F7F7F7', borderColor: '#F7F7F7' }}
              >
                <img src={face} alt="logo_face" />
              </Button>
              <Button
                size={34}
                shape="circle"
                href="https://www.linkedin.com/company/35683183/"
                target="_blank"
                rel="noopener noreferrer"
                style={{ backgroundColor: '#F7F7F7', borderColor: '#F7F7F7' }}
              >
                <img src={linke} alt="logo_face" />
              </Button>
              <Button
                size={34}
                shape="circle"
                href="https://www.instagram.com/labolsadesantiago/"
                target="_blank"
                rel="noopener noreferrer"
                style={{ backgroundColor: '#F7F7F7', borderColor: '#F7F7F7' }}
              >
                <img src={insta} alt="logo_face" />
              </Button>
            </Space>
          </Col>
        </Flex>
      </div>

      <div className="version-mobile">
        <Row justify='center' align='center'>
          <Col>
            <Text style={{ color: '#FFFBFA', fontSize: 20, fontFamily: 'Degular' }}>
              Síguenos en nuestras redes sociales
            </Text>
          </Col>
        </Row>
        <Col span={24}>
          <Divider style={{
            backgroundColor: '#FF411C',
            height: 1,
            marginTop: 14,
            marginBottom: 14
          }}></Divider>
        </Col>
        <Flex justify="end">
          <Space size={8}>
            <Button
                size={34}
                shape="circle"
                href="https://www.facebook.com/bolsadesantiago/"
                target="_blank"
                rel="noopener noreferrer"
                style={{ backgroundColor: '#F7F7F7' }}
              >
                <img src={face} alt="logo_face" />
              </Button>
              <Button
                size={34}
                shape="circle"
                href="https://www.linkedin.com/company/35683183/"
                target="_blank"
                rel="noopener noreferrer"
                style={{ backgroundColor: '#F7F7F7' }}
              >
                <img src={linke} alt="logo_face" />
              </Button>
              <Button
                size={34}
                shape="circle"
                href="https://www.instagram.com/labolsadesantiago/"
                target="_blank"
                rel="noopener noreferrer"
                style={{ backgroundColor: '#F7F7F7' }}
              >
                <img src={insta} alt="logo_face" />
              </Button>
          </Space>
        </Flex>
      </div>
    </div>
  )
}

export default FooterHome