import React from 'react'
import ContenidoDetalleProductoPersona from './ContenidoDetalleProductoPersona'
import { Row } from 'antd'
import ContentCardDetalleProdPersona from './ContentCardDetalleProdPersona'

const Index = () => {
  return (
    <Row className='margin-window'>
        <ContenidoDetalleProductoPersona/>
        <ContentCardDetalleProdPersona/>
    </Row>
  )
}

export default Index