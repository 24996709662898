import logoApi from '../../assets/logos/2 LOGO API horizontal sin isotipo.png'
import logoDashboards from '../../assets/logos/Logo Dashboards horizontal sin isotipo.svg'
import logoInnovationLab from '../../assets/logos/Logo Innovation Lab_ horizontal sin isotipo.svg'
import { TipoProductoId } from '../../enum/enum'

const LogoProducto = ({ idTipoProducto }) => {
    const getImageStyle = () => {
      let style = {
        maxWidth: '100%',
        maxHeight: '100%',
      };
      if (idTipoProducto === TipoProductoId.PACK_API) {
        style = { ...style, height: 65 };
      } else if (idTipoProducto === TipoProductoId.PACK_REPORTE || idTipoProducto === TipoProductoId.REPORTE) {
        style = { ...style, height: 45 };
      } else if (idTipoProducto === TipoProductoId.INNOVATION_LAB) {
        style = { ...style, height: 45 };
      }
      return style;
    };
  
    return (
      <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
        {idTipoProducto === TipoProductoId.PACK_API ? (
          <img alt="api" src={logoApi} style={getImageStyle()} className="logo-producto logo-producto-api" />
        ) : idTipoProducto === TipoProductoId?.PACK_REPORTE || idTipoProducto === TipoProductoId.REPORTE ? (
          <img alt="Dashboard" src={logoDashboards} style={getImageStyle()} className="logo-producto logo-producto-dash" />
        ) : idTipoProducto === TipoProductoId.INNOVATION_LAB ? (
          <img alt="innovationLab" src={logoInnovationLab} style={getImageStyle()} className="logo-producto logo-producto-inno" />
        ) : null}
      </div>
    );
  };

export default LogoProducto