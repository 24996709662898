import React from 'react'
import HeaderHome from '../Components/layout/Header'
import Index from '../Components/producto-persona/Index'
import FooterProd from '../Components/layout/FooterProd'


const ProductoPersonaPage = () => {
  return (
    <>
        <HeaderHome/>
        <Index/>
        <FooterProd/>
    </>
  )
}

export default ProductoPersonaPage