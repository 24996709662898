import React from 'react'
import FooterProd from '../Components/layout/FooterProd'
import Index from '../Components/producto-empresa/Index'
import HeaderHome from '../Components/layout/Header'


const ProductoEmpresaPage = () => {
  return (
    <>
        <HeaderHome/>
        <Index/>
        <FooterProd/>
    </>
  )
}

export default ProductoEmpresaPage