import React from 'react'
import HeaderHome from '../Components/layout/Header'
import FooterProd from '../Components/layout/FooterProd'
import Index from '../Components/detalle-producto-empresa/Index'


const DetalleProductoEmpresaPage = () => {
  return (
    <>
        <HeaderHome/>
        <Index/>
        <FooterProd/>
    </>
  )
}

export default DetalleProductoEmpresaPage